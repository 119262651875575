export enum RolesEnum {
  ADMIN = 'ADMIN',
  PH = 'PH',
  PH_INTERNAL = 'PH_INTERNAL',
  PH_EXTERNAL = 'PH_EXTERNAL',
  BOK = 'BOK',
  CT = 'CT',
  DWS = 'DWS',
  PZ = 'PZ',
  EXISTING_CUSTOMER = 'EXISTING CUSTOMER',
  NEW_CUSTOMER = 'NEW CUSTOMER',
  MANAGER = 'MANAGER',
  SALES_MANAGER = 'SALES_MANAGER',
  SP = 'SP',
  BIZ_ADM = 'BIZ_ADM'
}
