import { Component, Inject, OnInit } from '@angular/core';
import { FormControl, FormGroup, Validators } from '@angular/forms';
import { MAT_DIALOG_DATA, MatDialogRef } from '@angular/material';
import { UserIface } from '../../../../interfaces/user.iface';

@Component({
  selector: 'app-add-seller-account-id-dialog',
  templateUrl: './add-seller-account-id-dialog.component.html',
  styleUrls: ['./add-seller-account-id-dialog.component.scss']
})
export class AddSellerAccountIdDialogComponent implements OnInit {
  public form: FormGroup;

  constructor(
    @Inject(MAT_DIALOG_DATA) public data: UserIface,
    private refDialog: MatDialogRef<AddSellerAccountIdDialogComponent>
  ) {}

  ngOnInit() {
    this.createForm();
  }

  submit(): void {
    if (this.form.valid) {
      const sellerAccountId = this.form.get('sellerAccountId').value;
      this.data.metadata
        ? (this.data.metadata['sellerAccountId'] = sellerAccountId)
        : (this.data = { ...this.data, metadata: { sellerAccountId } });
      this.refDialog.close(this.data);
    }
  }

  private createForm(): void {
    this.form = new FormGroup({
      sellerAccountId: new FormControl('', Validators.required)
    });
  }
}
