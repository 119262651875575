import { Injectable } from '@angular/core';
import { Observable } from 'rxjs';
import { environment } from '../../environments/environment';
import { HttpClient } from '@angular/common/http';
import { CookieService } from 'ng2-cookies';
import { CredentialsIface } from '../interfaces/credentials.iface';
import { BehaviorSubject } from 'rxjs/internal/BehaviorSubject';
import { UserModel } from '../models/user.model';
import { UserIface } from '../interfaces/user.iface';
import { ChangePassword } from '../interfaces/change-password.interface';

@Injectable()
export class AuthService {
  private authToken: string;
  private currUser: UserIface;
  private featureToggles: object[];

  public userModel = new BehaviorSubject<UserModel>({} as UserModel);
  userModelChange = this.userModel.asObservable();

  constructor(private http: HttpClient, private cookieService: CookieService) {
    this.authToken = this.cookieService.get('token') || '';
  }

  signIn(credentials: CredentialsIface): Observable<object> {
    return this.http.post(environment.AUTH_API_URL, credentials);
  }

  changePasswordByUsername(credentials: ChangePassword): Observable<object> {
    return this.http.put(`${environment.AUTH_API_URL_CHANGE_PASSWORD}`, credentials);
  }

  get token() {
    return this.authToken;
  }

  set token(token: string) {
    this.authToken = token;
  }

  set user(user: any) {
    this.currUser = user;
  }

  get user(): any {
    return this.currUser;
  }

  verifyToken(): Observable<object> {
    return this.http.get(environment.AUTH_INFO_API_URL);
  }

  getIpAddress(): Observable<{ ip: string }> {
    return this.http.get<{ ip: string }>(environment.USER_IP);
  }

  logout(): void {
    this.http.get(environment.AUTH_LOGOUT_URL).subscribe(() => {
      this.cookieService.delete('token');
      return (this.authToken = '');
    });
  }

  set featureTogglesFlags(featureToggles) {
    this.featureToggles = featureToggles;
  }

  get featureTogglesFlags() {
    return this.featureToggles;
  }
}
