import { Component, OnInit } from '@angular/core';
import { FormBuilder, FormControl, FormGroup, Validators } from '@angular/forms';
import { ToastrService } from 'ngx-toastr';
import { UsersDataLimitService } from '../../../services/users-data-limit.service';
import { UserDataLimitIface } from '../../../interfaces/user-data-limit.iface';
import { TranslateService } from '@ngx-translate/core';

@Component({
  selector: 'app-users-data-limit',
  templateUrl: './users-data-limit.component.html',
  styleUrls: ['./users-data-limit.component.scss']
})
export class UsersDataLimitComponent implements OnInit {
  public usersDataLimitForm: FormGroup;
  public dataId;

  constructor(
    private formBuilder: FormBuilder,
    private toastr: ToastrService,
    private usersDataLimitService: UsersDataLimitService,
    private translate: TranslateService
  ) {
    const browserLang = translate.currentLang;
    translate.use(browserLang.match(/en|pl/) ? browserLang : 'pl');
    this.usersDataLimitForm = this.formBuilder.group({
      allowedAmount: new FormControl('', [Validators.required, Validators.min(1)]),
      email: new FormControl('', Validators.required)
    });
  }
  ngOnInit() {
    this.usersDataLimitService.getId().subscribe((res: UserDataLimitIface) => {
      this.dataId = res._id;
      this.usersDataLimitForm.patchValue({
        allowedAmount: res.value,
        email: res.email
      });
    });
  }

  changeAllowedAmount(form: FormGroup): void {
    const allowedAmount: UserDataLimitIface = {
      value: form.value.allowedAmount,
      email: form.value.email
    };
    this.usersDataLimitService.changeLimit(allowedAmount, this.dataId).subscribe(
      () => {
        this.toastr.success('Zmieniono dopuszczalną wartość');
      },
      () => {
        this.toastr.error('Niestety nie udało się zmienić dopuszczalnej wartości');
      }
    );
  }
}
