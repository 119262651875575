import { Injectable } from '@angular/core';
import { environment } from 'environments/environment';
import * as FileSaver from 'file-saver';
import { HttpClient } from '@angular/common/http';
import { AuthService } from './auth.service';
import { ToastrService } from 'ngx-toastr';
import { Buffer } from 'buffer';

@Injectable()
export class DownloadLogsService {
  static b64toBlob(b64Data: string, contentType: string): any {
    const bytesPackageSize = 512;
    const byteCharacters = atob(b64Data);
    const byteArrays = [];

    for (let offset = 0; offset < byteCharacters.length; offset += bytesPackageSize) {
      const slice = byteCharacters.slice(offset, offset + bytesPackageSize);
      const byteNumbers = new Array(slice.length);
      for (let i = 0; i < slice.length; i++) {
        byteNumbers[i] = slice.charCodeAt(i);
      }
      const byteArray = new Uint8Array(byteNumbers);
      byteArrays.push(byteArray);
    }
    return new Blob(byteArrays, { type: contentType });
  }

  constructor(private httpClient: HttpClient, private authService: AuthService, private toastsManager: ToastrService) {}

  downloadFile(directory: string, filename: string): void {
    this.httpClient
      .get<{ data: string; type: string }>(`${environment.FILES_API_URL}?directory=${directory}&filename=${filename}`)
      .subscribe(
        file => {
          if (file.hasOwnProperty('data')) {
            const { data, type } = file;
            const blob = DownloadLogsService.b64toBlob((Buffer as any).from(data).toString('base64'), type);
            FileSaver.saveAs(blob, filename, true);
            this.toastsManager.info('Plik został pobrany!');
          }
        },
        err => {
          if (err.status === 404) {
            this.toastsManager.error('Nie znaleziono takiego pliku', 'Uwaga!');
          }
        }
      );
  }
}
