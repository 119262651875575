export class LogsExport {
  private static readonly DATE_FROM: string = 'dateFrom';
  private static readonly DATE_TO: string = 'dateTo';
  private static readonly EVENTS_TIME_ATTRIBUTE: string = 'timestamp';
  private static readonly EVENTS_TAGS_ATTRIBUTE: string = 'tags';
  private static readonly USER_ROLE: string = 'userRole';
  private static readonly USER_NAME: string = 'userName';
  private static readonly DAY_IN_MILLISECONDS = 1000 * 60 * 60 * 24;

  private static mapFormFieldsWithDataAttributes(filterForm: object): object {
    const dateAttributes = [LogsExport.DATE_FROM, LogsExport.DATE_TO];
    Object.keys(filterForm).forEach(attribute => {
      if (dateAttributes.includes(attribute)) {
        try {
          filterForm[attribute] = new Date(filterForm[attribute]).getTime();
        } catch (e) {
          return (filterForm[attribute] = '');
        }
      }
    });
    return filterForm;
  }

  private static addDateToFilter(key, value): object {
    const obj = {};

    if (key === LogsExport.DATE_FROM) {
      obj[LogsExport.EVENTS_TIME_ATTRIBUTE] = { $gte: value };
      return obj;
    }
    if (key === LogsExport.DATE_TO) {
      value += LogsExport.DAY_IN_MILLISECONDS - 1;
      obj[LogsExport.EVENTS_TIME_ATTRIBUTE]
        ? (obj[LogsExport.EVENTS_TIME_ATTRIBUTE].$lte = value)
        : (obj[LogsExport.EVENTS_TIME_ATTRIBUTE] = { $lte: value });
      return obj;
    }
    if (key === LogsExport.USER_ROLE) {
      const splitValue = value.split(',').map(v => v.trim().toUpperCase());
      obj[LogsExport.EVENTS_TAGS_ATTRIBUTE] = { $elemMatch: { category: 'userRole', value: { $in: splitValue } } };
      return obj;
    }

    if (key === LogsExport.USER_NAME) {
      obj[LogsExport.EVENTS_TAGS_ATTRIBUTE] = { $elemMatch: { category: 'username', value: value } };
      return obj;
    }
  }

  public static getMongoFilter(filterForm: object) {
    filterForm = LogsExport.mapFormFieldsWithDataAttributes(filterForm);

    const dateAttributes = [LogsExport.DATE_FROM, LogsExport.DATE_TO, LogsExport.USER_ROLE, LogsExport.USER_NAME];
    let mongoFilter = {};

    const filters = Object.keys(filterForm)
      .filter(key => filterForm[key])
      .reduce((allFilters, key) => {
        let filter;
        if (dateAttributes.includes(key)) {
          filter = LogsExport.addDateToFilter(key, filterForm[key]);
        } else {
          const obj = {};
          obj[key] = filterForm[key];
          filter = obj;
        }
        return [...allFilters, filter];
      }, []);
    if (filters.length > 1) {
      mongoFilter = { $and: filters };
    } else if (filters.length === 1) {
      mongoFilter = filters[0];
    }
    return mongoFilter;
  }
}
