import { APP_INITIALIZER, Injector, NgModule } from '@angular/core';
import { HTTP_INTERCEPTORS, HttpClient, HttpClientModule } from '@angular/common/http';
import { AuthService } from './auth.service';
import { MyHttpInterceptor } from './my-http-interceptor';
import { UserService } from './user.service';
import { UserGroupsService } from './user-groups.service';
import { CustomerSegmentsService } from './customer-segment.service';
import { ExportService } from './export.service';
import { DownloadLogsService } from './download-logs.service';
import { EventsService } from './events.service';
import { UsersDataLimitService } from './users-data-limit.service';
import { HierarchyService } from './hierarchy.service';
import { environment } from '../../environments/environment';

@NgModule({
  imports: [HttpClientModule],
  providers: [
    AuthService,
    UserService,
    UserGroupsService,
    CustomerSegmentsService,
    ExportService,
    DownloadLogsService,
    EventsService,
    UsersDataLimitService,
    HierarchyService,
    {
      provide: HTTP_INTERCEPTORS,
      useClass: MyHttpInterceptor,
      multi: true
    }
  ]
})
export class ServicesModule {}
