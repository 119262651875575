import { Component, Inject, OnInit } from '@angular/core';
import { FormBuilder, FormControl, FormGroup, Validators } from '@angular/forms';
import { MatDialogRef, MAT_DIALOG_DATA } from '@angular/material';
import { TranslateService } from '@ngx-translate/core';
import { BlockUserReasonEnum } from '../../../../enums/block-user-reason.enum';
import { ChangeUserStatusInterface } from '../../../../interfaces/change-user-status.interface';
import { ConfirmDialogInterface } from '../../../../shared/interfaces/confirm-dialog.interface';
import { ToggleUserBaseComponent } from '../base/toggle-user-base.component';

@Component({
  selector: 'app-block-user-dialog',
  templateUrl: './block-user-dialog.component.html',
  styleUrls: ['./block-user-dialog.component.scss']
})
export class BlockUserDialogComponent extends ToggleUserBaseComponent<BlockUserDialogComponent> implements OnInit {
  constructor(
    @Inject(MAT_DIALOG_DATA) public data: ConfirmDialogInterface,
    protected refDialog: MatDialogRef<BlockUserDialogComponent>,
    protected formBuilder: FormBuilder,
    protected translate: TranslateService
  ) {
    super(refDialog, formBuilder, translate);
  }

  ngOnInit() {
    this.selectOptions = this.optionsFromEnum(BlockUserReasonEnum, 'BLOCK_USER_REASON_ENUM');
  }

  protected setupForm(): FormGroup {
    return this.formBuilder.group({
      message: new FormControl(''),
      reason: new FormControl('', Validators.required)
    });
  }
}
