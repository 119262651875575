import { CustomerSegmentIface, ShortenedCustomerSegmentIface } from '../interfaces/customer-segment.iface';
import { ShortenedGroupIface, UserIface } from '../interfaces/user.iface';
import { UserGroupIface } from '../interfaces/user-group.iface';

export class UserGroupModel {
  public name: string;
  public customerSegments: ShortenedCustomerSegmentIface[] = [];
  public users: UserIface[] = [];
  public selectedCustomerSegments?: CustomerSegmentIface[] = [];
  private usersToRemove: UserIface[] = [];
  public _id?: string;

  constructor(initData: UserGroupIface) {
    Object.assign(
      this,
      {
        name: '',
        users: [],
        customerSegments: []
      },
      initData
    );
  }

  static getShortenedGroup(segments: CustomerSegmentIface[]): ShortenedCustomerSegmentIface[] {
    return segments.map(segment => {
      return { name: segment.name, id: segment._id };
    });
  }

  getId(): string {
    return this._id;
  }

  addUser(user: UserIface) {
    if (!user.groups) {
      user.groups = [];
    }
    user.groups.push(this.shortenCurrentGroup());
    this.users.push({
      ...user,
      privileged: false
    });
  }

  removeUser(user: UserIface, index: number) {
    user.groups = user.groups.filter(group => group.id !== this.getId());
    this.usersToRemove.push(user);
    this.users.splice(index, 1);
  }

  addSegment(segment: CustomerSegmentIface): void {
    const isSegmentUnique = this.checkUniqueness(segment);
    if (isSegmentUnique) {
      return;
    }
    const { customers, ...segmentInfo } = segment;
    this.selectedCustomerSegments.push(segmentInfo);
  }

  removeSegment(index: number) {
    this.selectedCustomerSegments.splice(index, 1);
  }

  transformSegments(): void {
    this.customerSegments = UserGroupModel.getShortenedGroup(this.selectedCustomerSegments);
  }

  removeCustomersFromSegments(): void {
    this.selectedCustomerSegments = this.selectedCustomerSegments.map(segment => {
      const { customers, ...segmentInfo } = segment;
      return segmentInfo;
    });
  }

  shortenCurrentGroup(): ShortenedGroupIface {
    return { name: this.name, id: this.getId() };
  }

  private checkUniqueness(segment: CustomerSegmentIface): boolean {
    return !!this.selectedCustomerSegments.find(selectedSegment => selectedSegment._id === segment._id);
  }
}
