import { Injectable } from '@angular/core';
import { Observable } from 'rxjs';
import { UserGroupIface } from '../interfaces/user-group.iface';
import { HttpClient, HttpParams } from '@angular/common/http';
import { CRUD } from '../interfaces/crud.interface';
import { Count } from '../interfaces/count.interface';
import { environment } from '../../environments/environment';

@Injectable()
export class UserGroupsService implements CRUD<UserGroupIface> {
  constructor(private http: HttpClient) {}

  findAll(query: any, pageIndex: number, pageSize: number): Observable<UserGroupIface[]> {
    const params = new HttpParams()
      .set('filter', JSON.stringify(query))
      .set('pageIndex', pageIndex.toString())
      .set('pageSize', pageSize.toString());
    return this.http.get<UserGroupIface[]>(environment.AUTH_USERS_GROUP_API_URL, { params });
  }

  findById(id: string): Observable<UserGroupIface> {
    return this.http.get<UserGroupIface>(`${environment.AUTH_USERS_GROUP_API_URL}/${id}`);
  }

  count(query: any): Observable<Count> {
    const queryParams = new HttpParams().set('filter', JSON.stringify(query));
    return this.http.get<Count>(`${environment.AUTH_USERS_GROUP_API_URL}/count`, { params: queryParams });
  }

  create(userGroup: UserGroupIface): Observable<UserGroupIface> {
    return this.http.post<UserGroupIface>(environment.AUTH_USERS_GROUP_API_URL, userGroup);
  }

  update(id: string, userGroup: UserGroupIface): Observable<UserGroupIface> {
    return this.http.put<UserGroupIface>(`${environment.AUTH_USERS_GROUP_API_URL}/${id}`, userGroup);
  }

  remove(id: string): Observable<UserGroupIface> {
    return this.http.delete<UserGroupIface>(`${environment.AUTH_USERS_GROUP_API_URL}/${id}`);
  }
}
