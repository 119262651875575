import { Component, OnDestroy, OnInit } from '@angular/core';
import { CookieService } from 'ng2-cookies';
import { Router } from '@angular/router';
import { AuthService } from './services/auth.service';
import { UserModel } from './models/user.model';
import { Subscription } from 'rxjs/internal/Subscription';
import { TranslateService } from '@ngx-translate/core';
import { environment } from '../environments/environment';

@Component({
  selector: 'app-root',
  templateUrl: './app.component.html',
  styleUrls: ['./app.component.css']
})
export class AppComponent implements OnInit, OnDestroy {
  title = 'SaaS Manager';
  public user: UserModel = new UserModel();
  public subscription: Subscription;
  public appVersion = environment.version;

  constructor(
    private cookieService: CookieService,
    private router: Router,
    private authService: AuthService,
    public translate: TranslateService
  ) {
    this.manageLanguageSettings();
  }

  ngOnInit() {
    this.subscription = this.authService.userModelChange.subscribe(userModel => {
      if (!userModel.hasOwnProperty('username')) {
        this.user = new UserModel();
      } else {
        this.user = userModel;
      }
    });
  }

  ngOnDestroy() {
    this.subscription.unsubscribe();
  }

  logout() {
    this.router.navigate(['sign-in']).then(() => this.authService.logout());
  }

  checkIfLoggedIn(): boolean {
    return !!this.authService.token;
  }

  getFullLanguageName(language: string): string {
    return language === 'pl' ? 'Polski' : 'English';
  }

  manageLanguageSettings() {
    this.translate.addLangs(['en', 'pl']);
    this.translate.setDefaultLang('pl');
    const preferedLanguage = this.cookieService.get('language');
    if (preferedLanguage) {
      this.translate.use(preferedLanguage.match(/en|pl/) ? preferedLanguage : 'pl');
    } else {
      this.translate.use(this.translate.defaultLang.match(/en|pl/) ? this.translate.defaultLang : 'pl');
    }
    this.translate.onLangChange.subscribe(change => {
      if (change.lang.match(/en|pl/)) {
        this.cookieService.set('language', change.lang);
      }
    });
  }
}
