import { CommonModule } from '@angular/common';
import { NgModule } from '@angular/core';
import { FormsModule, ReactiveFormsModule } from '@angular/forms';
import { RouterModule } from '@angular/router';
import { TranslateModule } from '@ngx-translate/core';
import { MaterialModule } from '../material/material.module';
import { AccountComponent } from './account/account.component';
import { UserAddComponent } from './user-add/user-add.component';
import { UserGroupEditComponent } from './user-groups/user-group-edit/user-group-edit.component';
import { UserGroupListComponent } from './user-groups/user-group-list/user-group-list.component';
import { UserGroupsComponent } from './user-groups/user-groups.component';
import { BlockUserDialogComponent } from './user-list/block-user-dialog/block-user-dialog.component';
import { EditMetadataDialogComponent } from './user-list/edit-metadata-dialog/edit-metadata-dialog.component';
import { EditRolesDialogComponent } from './user-list/edit-roles-dialog/edit-roles-dialog.component';
import { SetPasswordDialogComponent } from './user-list/set-password-dialog/set-password-dialog.component';
import { UnlockUserDialogComponent } from './user-list/unlock-user-dialog/unlock-user-dialog.component';
import { UserListComponent } from './user-list/user-list.component';
import { UsersDataLimitComponent } from './users-data-limit/users-data-limit.component';
import { AddUserHierarchyDialogComponent } from './users-hierarchy/add-user-hierarchy-dialog/add-user-hierarchy-dialog.component';
import { UsersHierarchyComponent } from './users-hierarchy/users-hierarchy.component';
import { UserHistoryStatusItemComponent } from './user-list/unlock-user-dialog/user-history-status-item/user-history-status-item.component';
import { DragDropModule } from '@angular/cdk/drag-drop';
import { AddUserDialogComponent } from './users-hierarchy/add-user-dialog/add-user-dialog.component';
import { AddSalesmanCodeDialogComponent } from './user-list/add-salesman-code-dialog/add-salesman-code-dialog.component';
import { AddSellerAccountIdDialogComponent } from './user-list/add-seller-account-id-dialog/add-seller-account-id-dialog.component';

@NgModule({
  imports: [
    MaterialModule,
    CommonModule,
    FormsModule,
    ReactiveFormsModule,
    RouterModule,
    TranslateModule,
    DragDropModule
  ],
  declarations: [
    UserListComponent,
    UserAddComponent,
    UserGroupsComponent,
    UserGroupEditComponent,
    UserGroupListComponent,
    UsersHierarchyComponent,
    AccountComponent,
    EditRolesDialogComponent,
    EditMetadataDialogComponent,
    SetPasswordDialogComponent,
    AddUserHierarchyDialogComponent,
    AddUserDialogComponent,
    UsersDataLimitComponent,
    BlockUserDialogComponent,
    UnlockUserDialogComponent,
    UserHistoryStatusItemComponent,
    AddSalesmanCodeDialogComponent,
    AddSellerAccountIdDialogComponent
  ],
  entryComponents: [
    EditRolesDialogComponent,
    EditMetadataDialogComponent,
    SetPasswordDialogComponent,
    AddUserHierarchyDialogComponent,
    AddUserDialogComponent,
    BlockUserDialogComponent,
    UnlockUserDialogComponent,
    AddSalesmanCodeDialogComponent,
    AddSellerAccountIdDialogComponent
  ]
})
export class UsersModule {}
