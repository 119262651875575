import { NgModule } from '@angular/core';
import { RouterModule, Routes } from '@angular/router';
import { SignInComponent } from './modules/auth/sign-in.component';
import { AuthGuard } from './services/auth.guard';
import { UserListComponent } from './modules/users/user-list/user-list.component';
import { UserAddComponent } from './modules/users/user-add/user-add.component';
import { AccountComponent } from './modules/users/account/account.component';
import { UserGroupsComponent } from './modules/users/user-groups/user-groups.component';
import { UserGroupEditComponent } from './modules/users/user-groups/user-group-edit/user-group-edit.component';
import { UsersHierarchyComponent } from './modules/users/users-hierarchy/users-hierarchy.component';
import { ExportLogsComponent } from './modules/export-logs/export-logs.component';
import { UsersDataLimitComponent } from './modules/users/users-data-limit/users-data-limit.component';

const routes: Routes = [
  { path: 'sign-in', component: SignInComponent },
  { path: 'user-list', component: UserListComponent, canActivate: [AuthGuard] },
  { path: 'account', component: AccountComponent, canActivate: [AuthGuard] },
  { path: 'user-add', component: UserAddComponent, canActivate: [AuthGuard] },
  { path: 'user-group-list', component: UserGroupsComponent, canActivate: [AuthGuard] },
  { path: 'users-hierarchy', component: UsersHierarchyComponent, canActivate: [AuthGuard] },
  { path: 'users-data-limit', component: UsersDataLimitComponent, canActivate: [AuthGuard] },
  { path: 'user-group-edit', component: UserGroupEditComponent, canActivate: [AuthGuard] },
  { path: 'user-group-edit/:id', component: UserGroupEditComponent, canActivate: [AuthGuard] },
  { path: 'export-logs', component: ExportLogsComponent, canActivate: [AuthGuard] },
  { path: '', redirectTo: '/account', pathMatch: 'full' }
];

@NgModule({
  imports: [RouterModule.forRoot(routes)],
  exports: [RouterModule]
})
export class AppRoutingModule {}
