import { CollectionViewer, DataSource } from '@angular/cdk/collections';
import { BehaviorSubject, Observable, of } from 'rxjs';
import { catchError, finalize } from 'rxjs/operators';
import { LoggerService } from '../services/logger.service';

export class RESTDataSource<T> implements DataSource<T> {
  private dataSubject = new BehaviorSubject<T[]>([]);
  private loadingSubject = new BehaviorSubject<boolean>(false);
  private countSubject = new BehaviorSubject<number>(0);

  public loading$ = this.loadingSubject.asObservable();
  public count$ = this.countSubject.asObservable();

  constructor(private crud) {}

  removeListItemByPropertyValue(property: string, value: any) {
    const data: T[] = this.dataSubject.getValue();
    this.dataSubject.next(data.filter((item: T) => item[property] !== value));
  }

  setDataSourceValueByProp(
    propNameToFind: string,
    propValue: any,
    propertyToChange: string,
    propertyToChangeValue: any
  ) {
    const data: T[] = this.dataSubject.getValue();
    data.forEach((item: T) => {
      if (item[propNameToFind] === propValue) {
        item[propertyToChange] = propertyToChangeValue;
      }
    });

    this.dataSubject.next(data);
  }

  setDataSource(data): void {
    this.dataSubject.next(data);
  }

  setCount(count: number): void {
    this.countSubject.next(count);
  }

  connect(collectionViewer: CollectionViewer): Observable<T[]> {
    return this.dataSubject.asObservable();
  }

  disconnect(collectionViewer: CollectionViewer): void {
    this.dataSubject.complete();
    this.loadingSubject.complete();
  }

  loadData(query) {
    LoggerService.log('RESTDataSource::loadData');
    this.loadingSubject.next(true);

    this.crud
      .findAll(query.filters, query.pageIndex, query.pageSize)
      .pipe(
        catchError(() => of([])),
        finalize(() => this.loadingSubject.next(false))
      )
      .subscribe(data => this.dataSubject.next(data));

    this.crud.count(query.filters).subscribe(count => this.countSubject.next(count.matchingResults));
  }

  loadUsersData(query: object, pageIndex: number, pageSize: number): void {
    this.loadingSubject.next(true);

    this.crud
      .findAll(query, pageIndex, pageSize)
      .pipe(
        catchError(() => of([])),
        finalize(() => this.loadingSubject.next(false))
      )
      .subscribe(data => {
        this.dataSubject.next(data);
      });

    this.crud.count(query).subscribe(count => this.countSubject.next(count.matchingResults));
  }
}
