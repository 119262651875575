import { HttpErrorResponse, HttpEvent, HttpHandler, HttpInterceptor, HttpRequest } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { Router } from '@angular/router';
import { CookieService } from 'ng2-cookies';
import { Observable } from 'rxjs';
import { catchError } from 'rxjs/operators';
import { AuthService } from './auth.service';

@Injectable()
export class MyHttpInterceptor implements HttpInterceptor {
  constructor(private authService: AuthService, private router: Router, private cookieService: CookieService) {}
  intercept(request: HttpRequest<any>, next: HttpHandler): Observable<HttpEvent<any>> {
    const headers = {
      Authorization: this.authService.token
    };

    if (request.method !== 'GET') {
      headers['X-XSRF-TOKEN'] = this.cookieService.get('XSRF-TOKEN');
    }

    request = request.clone({
      setHeaders: headers,
      withCredentials: true
    });

    return next.handle(request).pipe(
      catchError((err: any) => {
        if (err instanceof HttpErrorResponse) {
          if (err.status === 403) {
            this.router.navigate(['sign-in']).then(() => this.authService.logout());
          }
        }

        throw err;
      })
    );
  }
}
