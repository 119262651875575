import { Component, ElementRef, Inject, OnInit, ViewChild } from '@angular/core';
import { MAT_DIALOG_DATA, MatAutocompleteSelectedEvent, MatDialogRef } from '@angular/material';
import { UserIface } from '../../../../interfaces/user.iface';
import { ToastrService } from 'ngx-toastr';
import { TranslateService } from '@ngx-translate/core';
import { SupportedRoles } from '../../../../enums/supportedRoles.enum';
import { FormControl, FormGroup } from '@angular/forms';
import { Observable } from 'rxjs';
import { map, startWith } from 'rxjs/operators';

@Component({
  selector: 'app-edit-roles-dialog',
  templateUrl: './edit-roles-dialog.component.html',
  styleUrls: ['./edit-roles-dialog.component.scss']
})
export class EditRolesDialogComponent implements OnInit {
  public user: UserIface;
  public supportedRoles = Object.values(SupportedRoles);
  public addRolesForm: FormGroup;
  filteredRoles$: Observable<string[]>;

  @ViewChild('roleInput') roleInput: ElementRef<HTMLInputElement>;

  constructor(
    @Inject(MAT_DIALOG_DATA) public data: UserIface,
    private refDialog: MatDialogRef<EditRolesDialogComponent>,
    private toastr: ToastrService,
    private translate: TranslateService
  ) {}

  ngOnInit() {
    this.translateInit();
    this.getUser(this.data);

    this.filteredRoles$ = this.addRolesForm.get('roleInput').valueChanges.pipe(
      startWith(''),
      map(role => this.filterRoles(role))
    );
  }

  private translateInit(): void {
    const browserLang = this.translate.currentLang;
    this.translate.use(browserLang.match(/en|pl/) ? browserLang : 'pl');
  }

  getUser(data): void {
    try {
      this.user = JSON.parse(JSON.stringify(data));
      this.createForm();
    } catch (err) {
      this.toastr.warning('Coś poszło nie tak', 'Ooops!');
      this.refDialog.close(false);
    }
  }

  private createForm(): void {
    this.addRolesForm = new FormGroup({
      roles: new FormControl(this.user.roles, []),
      roleInput: new FormControl('')
    });
  }

  submit(): UserIface {
    const roles = this.addRolesForm.get('roles').value.splice('');
    this.user.roles = roles;
    return this.user;
  }

  selectRole(event: MatAutocompleteSelectedEvent): void {
    const newRole = event.option.viewValue;
    const currentRoles = this.addRolesForm.get('roles').value;
    currentRoles.push(newRole);

    this.addRolesForm.get('roles').setValue(currentRoles);
    this.addRolesForm.get('roleInput').setValue('');

    this.roleInput.nativeElement.value = '';
    this.roleInput.nativeElement.blur();
  }

  removeRole(roleToRemove: string): void {
    const rolesControl = this.addRolesForm.get('roles') as FormControl;
    const currentRoles = rolesControl.value;

    const updatedRoles = currentRoles.filter(role => roleToRemove !== role);

    rolesControl.setValue(updatedRoles);
  }

  private filterRoles(filterValue: string): string[] {
    const noActiveRoles = this.supportedRoles.filter(role => !this.addRolesForm.get('roles').value.includes(role));

    return noActiveRoles.filter(role => role.toLowerCase().includes(filterValue.toLowerCase()));
  }
}
