import { Injectable } from '@angular/core';
import { BehaviorSubject } from 'rxjs';
import { FileNode } from './user-hierarchy-filenode';
import { HierarchyService } from '../../../services/hierarchy.service';

@Injectable()
export class FileDatabase {
  dataChange = new BehaviorSubject<FileNode[]>([]);

  get data(): FileNode[] {
    return this.dataChange.value;
  }

  constructor(private hierarchyService: HierarchyService) {
    this.initialize();
  }

  initialize() {
    this.hierarchyService.fetch().subscribe(hierarchy => {
      if (hierarchy) {
        this.hierarchyService.hierarchyId = hierarchy._id;
        const data = this.buildFileTree(hierarchy.tree, 0);
        this.dataChange.next(data);
      }
    });
  }

  buildFileTree(obj: object, level: number): FileNode[] {
    return Object.keys(obj).reduce<FileNode[]>((accumulator, key) => {
      const value = obj[key];
      const node = new FileNode();
      node.filename = key;

      if (value != null) {
        if (typeof value === 'object') {
          node.children = this.buildFileTree(value, level + 1);
        } else {
          node.type = value;
        }
      }

      return accumulator.concat(node);
    }, []);
  }

  insertItem(parent: FileNode, name: string): FileNode {
    if (!parent.children) {
      parent.children = [];
    }
    let newNode = new FileNode();
    newNode.filename = name;
    newNode.children = [];

    parent.children.push(newNode);
    this.dataChange.next(this.data);
    return newNode;
  }

  insertItemAbove(node: FileNode, name: string): FileNode {
    const parentNode = this.getParentFromNodes(node);
    let newNode = new FileNode();
    newNode.filename = name;
    newNode.children = [];

    if (parentNode != null) {
      parentNode.children.splice(parentNode.children.indexOf(node), 0, newNode);
    } else {
      this.data.splice(this.data.indexOf(node), 0, newNode);
    }
    this.dataChange.next(this.data);
    return newNode;
  }

  insertItemBelow(node: FileNode, name: string): FileNode {
    const parentNode = this.getParentFromNodes(node);
    let newNode = new FileNode();
    newNode.filename = name;
    newNode.children = [];

    if (parentNode != null) {
      parentNode.children.splice(parentNode.children.indexOf(node) + 1, 0, newNode);
    } else {
      this.data.splice(this.data.indexOf(node) + 1, 0, newNode);
    }
    this.dataChange.next(this.data);
    return newNode;
  }

  getParentFromNodes(node: FileNode): FileNode {
    for (let i = 0; i < this.data.length; ++i) {
      const currentRoot = this.data[i];
      const parent = this.getParent(currentRoot, node);
      if (parent != null) {
        return parent;
      }
    }
    return null;
  }

  getParent(currentRoot: FileNode, node: FileNode): FileNode {
    if (currentRoot.children && currentRoot.children.length > 0) {
      for (let i = 0; i < currentRoot.children.length; ++i) {
        const child = currentRoot.children[i];
        if (child === node) {
          return currentRoot;
        } else if (child.children && child.children.length > 0) {
          const parent = this.getParent(child, node);
          if (parent != null) {
            return parent;
          }
        }
      }
    }
    return null;
  }

  updateItem(node: FileNode, name: string) {
    node.filename = name;
    this.dataChange.next(this.data);
  }

  deleteItem(node: FileNode) {
    this.deleteNode(this.data, node);
    this.dataChange.next(this.data);
  }

  copyPasteItem(from: FileNode, to: FileNode): FileNode {
    const newItem = this.insertItem(to, from.filename);
    if (from.children) {
      from.children.forEach(child => {
        this.copyPasteItem(child, newItem);
      });
    }
    return newItem;
  }

  copyPasteItemAbove(from: FileNode, to: FileNode): FileNode {
    const newItem = this.insertItemAbove(to, from.filename);
    if (from.children) {
      from.children.forEach(child => {
        this.copyPasteItem(child, newItem);
      });
    }
    return newItem;
  }

  copyPasteItemBelow(from: FileNode, to: FileNode): FileNode {
    const newItem = this.insertItemBelow(to, from.filename);
    if (from.children) {
      from.children.forEach(child => {
        this.copyPasteItem(child, newItem);
      });
    }
    return newItem;
  }

  deleteNode(nodes: FileNode[], nodeToDelete: FileNode) {
    const index = nodes.indexOf(nodeToDelete, 0);
    if (index > -1) {
      nodes.splice(index, 1);
    } else {
      nodes.forEach(node => {
        if (node.children && node.children.length > 0) {
          this.deleteNode(node.children, nodeToDelete);
        }
      });
    }
  }

  parseToObject(data: FileNode, object = {}): { [key: string]: any } {
    const obj = object;
    if (!obj[data.filename]) {
      obj[data.filename] = {};
    }

    data.children.forEach(child => {
      if (child.children.length) {
        child.children.forEach(c => {
          obj[data.filename][child.filename] = this.parseToObject(c, obj[data.filename][child.filename]);
        });
      } else {
        obj[data.filename][child.filename] = {};
      }
    });

    return obj;
  }
}
