import { AfterViewInit, Component, ElementRef, OnDestroy, OnInit, ViewChild } from '@angular/core';
import { debounceTime, distinctUntilChanged, map, startWith, tap } from 'rxjs/operators';
import { UserIface } from '../../../../interfaces/user.iface';
import { FormControl } from '@angular/forms';
import { Observable, Subscription, fromEvent } from 'rxjs';
import { UserService } from '../../../../services/user.service';
import { ActivatedRoute, Router } from '@angular/router';
import { UserGroupsService } from '../../../../services/user-groups.service';
import { MatPaginator } from '@angular/material';
import { RESTDataSource } from '../../../../helpers/rest.datasource';
import { CustomerSegmentsService } from '../../../../services/customer-segment.service';
import { CustomerSegmentIface } from '../../../../interfaces/customer-segment.iface';
import { UserGroupModel } from '../../../../models/user-group-edit.model';
import { ToastrService } from 'ngx-toastr';
import { TableParametersIface } from '../../../../interfaces/tableParameters.iface';
import { TranslateService } from '@ngx-translate/core';

@Component({
  selector: 'app-user-group-edit',
  templateUrl: './user-group-edit.component.html',
  styleUrls: ['./user-group-edit.component.scss']
})
export class UserGroupEditComponent implements OnInit, AfterViewInit, OnDestroy {
  @ViewChild(MatPaginator)
  paginator: MatPaginator;
  @ViewChild('filterRef')
  filterRef: ElementRef;

  public dataSource: RESTDataSource<UserIface>;
  public filterValue: string;
  public displayedColumns = ['action', 'username', 'roles'];
  public isLoadingResults = false;
  public subscription: Subscription;
  public users: UserIface[] = [];
  public userGroup = new UserGroupModel({} as UserGroupModel);
  public customerSegment = new FormControl('');
  public segments: CustomerSegmentIface[] = [];
  public id: string;
  public selectedCustomerSegments: CustomerSegmentIface[] = [];
  public filteredSegments: Observable<object[]>;

  constructor(
    private userService: UserService,
    private userGroupsService: UserGroupsService,
    private route: ActivatedRoute,
    private customerSegmentService: CustomerSegmentsService,
    private router: Router,
    private toastr: ToastrService,
    private translate: TranslateService
  ) {
    const browserLang = translate.currentLang;
    translate.use(browserLang.match(/en|pl/) ? browserLang : 'pl');
  }

  private findSegments(segments = []) {
    return this.segments.filter(x => segments.findIndex(y => x._id === y.id) !== -1);
  }

  ngOnInit() {
    this.filteredSegments = this.customerSegment.valueChanges.pipe(
      startWith(''),
      map(val => this.filter(val))
    );
    this.customerSegmentService.findAll({}, 0, 0).subscribe(segments => {
      this.segments = segments;
      this.route.params.subscribe(params => {
        if (params.id) {
          this.userGroupsService.findById(params.id).subscribe(group => {
            this.userGroup = new UserGroupModel(group);
            this.loadData({});
            this.userGroup.selectedCustomerSegments = this.findSegments(group.customerSegments);
          });
        }
      });
    });
    this.dataSource = new RESTDataSource(this.userService);
    this.subscription = this.dataSource.loading$.subscribe(state => {
      this.isLoadingResults = state;
    });
    this.paginator.pageSize = 10;
    this.loadData({});
    fromEvent(this.filterRef.nativeElement, 'keyup')
      .pipe(
        map((evt: any) => evt.target.value),
        debounceTime(500),
        distinctUntilChanged()
      )
      .subscribe((text: string) => this.applyInputFilter(text));
  }

  ngAfterViewInit() {
    this.paginator.page.pipe(tap(() => this.loadData({}))).subscribe();
  }

  ngOnDestroy() {
    this.subscription.unsubscribe();
  }

  loadData(filter: object): void {
    filter = Object.assign(filter, { username: { $nin: this.userGroup.users.map(u => u.username) } });
    const query: TableParametersIface = {
      filters: filter,
      pageIndex: this.paginator.pageIndex,
      pageSize: this.paginator.pageSize
    };
    this.dataSource.loadData(query);
  }

  chooseUser(user: UserIface): void {
    const exists = this.userGroup.users.some(u => u.username === user.username);
    if (!exists) {
      this.userGroup.addUser(user);
    }
    this.applyInputFilter(this.filterValue);
  }

  filter(val: string) {
    return this.segments.filter(option => {
      if (option.name) {
        return option.name
          .toLowerCase()
          .trim()
          .includes(val.toLowerCase());
      }
    });
  }

  removeSegment(index: number): void {
    this.userGroup.removeSegment(index);
  }

  toggleSegment(segment: CustomerSegmentIface) {
    this.userGroup.addSegment(segment);
  }

  deleteUser(user: UserIface, index: number): void {
    this.userGroup.removeUser(user, index);
    this.applyInputFilter(this.filterValue);
  }

  saveGroup(): void {
    const id = this.userGroup.getId();
    this.userGroup.transformSegments();
    this.userGroup.removeCustomersFromSegments();
    if (id) {
      this.userGroupsService.update(id, this.userGroup).subscribe(
        () => {
          this.router.navigate(['/user-group-list']);
          this.toastr.info('Grupa została zapisana');
        },
        () => {
          this.toastr.error('Spróbuj jeszcze raz', 'Nie udało się zapisać grupy');
        }
      );
    } else {
      this.userGroupsService.create(this.userGroup).subscribe(
        () => {
          this.router.navigate(['/user-group-list']);
          this.toastr.info('Grupa została zapisana');
        },
        () => {
          this.toastr.error('Spróbuj jeszcze raz', 'Nie udało się zapisać grupy');
        }
      );
    }
  }

  applyInputFilter(filterValue: string): void {
    if (!filterValue) {
      this.loadData({});
      return;
    }
    const filter = {
      $or: [
        {
          username: { $regex: filterValue, $options: 'i' }
        },
        {
          roles: { $elemMatch: { $regex: filterValue, $options: 'i' } }
        }
      ]
    };
    this.loadData(filter);
  }
}
