import { Component } from '@angular/core';
import { FormControl, FormGroup } from '@angular/forms';
import { ExportService } from '../../../services/export.service';
import { MatDialogRef } from '@angular/material';
import { LogsExport } from '../../../helpers/logs-export';
import { ToastrService } from 'ngx-toastr';
import { TranslateService } from '@ngx-translate/core';

@Component({
  selector: 'app-export-logs-dialog',
  templateUrl: './export-logs-dialog.component.html',
  styleUrls: ['./export-logs-dialog.component.scss']
})
export class ExportLogsDialogComponent {
  private static readonly EXPORT_TYPE: string = 'LOGS_EXPORT';
  private static readonly LOGS_EVENT_TYPE: string = 'user_fetched';
  public exportLogsFilterForm: FormGroup;
  public filename: string;

  constructor(
    private exportService: ExportService,
    private toastr: ToastrService,
    private refDialog: MatDialogRef<ExportLogsDialogComponent>,
    private translate: TranslateService
  ) {
    const browserLang = translate.currentLang;
    translate.use(browserLang.match(/en|pl/) ? browserLang : 'pl');
    this.exportLogsFilterForm = new FormGroup({
      userRole: new FormControl(''),
      userName: new FormControl(''),
      dateFrom: new FormControl(''),
      dateTo: new FormControl('')
    });
  }

  exportLogs(form: FormGroup): void {
    let filter = {
      eventType: ExportLogsDialogComponent.LOGS_EVENT_TYPE,
      ...form.getRawValue()
    };

    filter = LogsExport.getMongoFilter(filter);
    this.exportService.runExport(filter, this.filename, ExportLogsDialogComponent.EXPORT_TYPE).subscribe(() => {
      this.toastr.info('Prosimy o cierpliwość', 'Dane pomyślnie przekazane do wygenerowania raportu!');
      this.refDialog.close();
    });
  }

  isFilterFormValid(): boolean {
    return this.filename && this.exportLogsFilterForm.valid;
  }
}
