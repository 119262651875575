import { Injectable } from '@angular/core';
import { CanActivate, Router } from '@angular/router';

import { CookieService } from 'ng2-cookies';
import { AuthService } from './auth.service';
import { UserModel } from '../models/user.model';
import { UserIface } from '../interfaces/user.iface';

@Injectable()
export class AuthGuard implements CanActivate {
  constructor(private router: Router, private cookieService: CookieService, private authService: AuthService) {}

  canActivate(): Promise<boolean> {
    return new Promise(resolve => {
      this.authService.verifyToken().subscribe(
        (user: UserIface) => {
          if (user) {
            this.authService.featureTogglesFlags = user.featureToggles;
            this.authService.user = user;
            this.authService.userModel.next(new UserModel(user));
            return resolve(true);
          }
          this.router.navigate(['/sign-in'], { queryParams: { sessionExpired: true } });
          return resolve(false);
        },
        () => {
          this.router.navigate(['/sign-in'], { queryParams: { sessionExpired: true } });
          return resolve(false);
        }
      );
    });
  }
}
