import { RolesEnum } from '../enums/roles.enum';
import { UserIface } from '../interfaces/user.iface';

export class UserModel {
  public rolesEnum;
  private readonly username: string;
  private readonly roles: string[];
  private readonly token: string;
  private readonly userType: string;
  private readonly userId: string;

  private readonly ttl: string;
  private readonly isApiToken: string;
  private readonly created: string;

  constructor(initData: UserIface = {} as UserIface) {
    Object.assign(this, initData);
    this.rolesEnum = RolesEnum;
  }

  isDWS(): boolean {
    return this.roles.includes(this.rolesEnum.DWS) || this.isAdmin();
  }

  isCT(): boolean {
    return this.roles.includes(this.rolesEnum.CT) || this.isAdmin();
  }

  isPh(): boolean {
    return this.roles.includes(this.rolesEnum.PH) || this.isAdmin();
  }

  isPhInternal(): boolean {
    return this.roles.includes(this.rolesEnum.PH_INTERNAL) || this.isAdmin();
  }

  isPhExternal(): boolean {
    return this.roles.includes(this.rolesEnum.PH_EXTERNAL) || this.isAdmin();
  }

  isPZ(): boolean {
    return this.roles.includes(this.rolesEnum.PZ) || this.isAdmin();
  }

  isAdmin(): boolean {
    return this.roles.includes(this.rolesEnum.ADMIN);
  }

  isManager(): boolean {
    return this.roles.includes(this.rolesEnum.MANAGER) || this.isAdmin();
  }

  isSP(): boolean {
    return this.roles.includes(this.rolesEnum.SP) || this.isAdmin();
  }

  isBizAdm(): boolean {
    return this.roles.includes(this.rolesEnum.BIZ_ADM) || this.isAdmin();
  }

  isBOK(): boolean {
    return this.roles.includes(this.rolesEnum.BOK) || this.isAdmin();
  }

  getSalesChannel(): string {
    return this.isDWS()
      ? this.rolesEnum.DWS
      : this.isCT()
      ? this.rolesEnum.CT
      : this.isPh()
      ? this.rolesEnum.PH
      : this.isPhInternal()
      ? this.rolesEnum.PH_INTERNAL
      : this.isPhExternal()
      ? this.rolesEnum.PH_EXTERNAL
      : this.isManager()
      ? this.rolesEnum.MANAGER
      : this.isSP()
      ? this.rolesEnum.SP
      : this.isBizAdm()
      ? this.rolesEnum.BIZ_ADM
      : this.isBOK()
      ? this.rolesEnum.BOK
      : this.rolesEnum.PZ;
  }

  get(key: string): any {
    return this[key];
  }

  hasRequiredRole(roles: string[]): boolean {
    return this.roles.some(role => roles.includes(role));
  }

  canAccessNewCustomer(): boolean {
    return this.roles.includes(this.rolesEnum.NEW_CUSTOMER) || this.isAdmin();
  }

  canAccessExistingCustomer(): boolean {
    return this.roles.includes(this.rolesEnum.EXISTING_CUSTOMER) || this.isAdmin();
  }
}
