import { Component, Inject, ViewChild } from '@angular/core';
import { MetadataIface, UserIface } from '../../../../interfaces/user.iface';
import { MAT_DIALOG_DATA, MatDialogRef, MatPaginator, MatTableDataSource } from '@angular/material';
import { ToastrService } from 'ngx-toastr';
import { TranslateService } from '@ngx-translate/core';

@Component({
  selector: 'app-edit-metadata-dialog',
  templateUrl: './edit-metadata-dialog.component.html',
  styleUrls: ['./edit-metadata-dialog.component.scss']
})
export class EditMetadataDialogComponent {
  public user: UserIface;
  public dataSource: MatTableDataSource<MetadataIface>;
  public displayedColumns = ['key', 'value'];
  public metadata: MetadataIface[] = [];

  @ViewChild(MatPaginator) paginator: MatPaginator;

  private static parseMetadataToIface(metadata: object): MetadataIface[] {
    const dataArray: MetadataIface[] = [];
    Object.keys(metadata).forEach(key => {
      dataArray.push({ key, value: metadata[key] });
    });
    return dataArray;
  }

  constructor(
    @Inject(MAT_DIALOG_DATA) data: UserIface,
    private refDialog: MatDialogRef<EditMetadataDialogComponent>,
    private toastr: ToastrService,
    private translate: TranslateService
  ) {
    const browserLang = translate.currentLang;
    translate.use(browserLang.match(/en|pl/) ? browserLang : 'pl');
    try {
      this.user = JSON.parse(JSON.stringify(data));
      this.user.metadata
        ? (this.metadata = EditMetadataDialogComponent.parseMetadataToIface(this.user.metadata))
        : (this.user.metadata = {});
      this.dataSource = new MatTableDataSource(this.metadata);
    } catch (err) {
      this.toastr.warning('Coś poszło nie tak', 'Ooops!');
      this.refDialog.close(false);
    }
  }

  public addMetadata(): void {
    this.metadata.push({ key: '', value: '' });
    this.dataSource.data = this.metadata;
    this.dataSource.paginator = this.paginator;
  }

  public deleteMetadata(index: number): void {
    this.metadata.splice(index, 1);
    this.dataSource.data = this.metadata;
  }

  public saveMetadata(): void {
    this.user.metadata = {};
    this.metadata.forEach(metadata => {
      this.user.metadata[metadata.key] = metadata.value;
    });
    this.refDialog.close(this.user);
  }
}
