import { NgModule } from '@angular/core';
import { SignInComponent } from './sign-in.component';
import { AuthorizationFormComponent } from './authorization-form/authorization-form.component';
import { BrowserModule } from '@angular/platform-browser';
import { ReactiveFormsModule } from '@angular/forms';
import { MaterialModule } from '../material/material.module';
import { TranslateModule } from '@ngx-translate/core';
import { ChangePasswordFormComponent } from './change-password-form/change-password-form.component';
import { RECAPTCHA_V3_SITE_KEY, RecaptchaV3Module } from 'ng-recaptcha';
import { environment } from '../../../environments/environment';

@NgModule({
  imports: [BrowserModule, ReactiveFormsModule, MaterialModule, TranslateModule, RecaptchaV3Module],
  declarations: [SignInComponent, AuthorizationFormComponent, ChangePasswordFormComponent],
  providers: [
    {
      provide: RECAPTCHA_V3_SITE_KEY,
      useValue: environment.CAPTCHA_SITE_KEY
    }
  ]
})
export class AuthModule {}
