import { Injectable } from '@angular/core';
import { environment } from '../../environments/environment';
import { UserIface } from '../interfaces/user.iface';
import { HttpClient, HttpParams } from '@angular/common/http';
import { Observable } from 'rxjs';
import { Count } from '../interfaces/count.interface';
import { UserHistoryStatusInterface } from '../interfaces/user-history-status.iface';
import { BlockUserReasonEnum } from '../enums/block-user-reason.enum';
import { ChangeUserStatusInterface } from '../interfaces/change-user-status.interface';
import { UnlockUserReasonEnum } from '../enums/unlock-user-reason.enum';

@Injectable()
export class UserService {
  private readonly url = `${environment.AUTH_USERS_API_URL}`;

  constructor(private http: HttpClient) {}

  findAll(query: any, pageIndex: number, pageSize: number): Observable<UserIface[]> {
    const params = new HttpParams()
      .set('filter', JSON.stringify(query))
      .set('pageIndex', pageIndex.toString())
      .set('pageSize', pageSize.toString());
    return this.http.get<UserIface[]>(this.url, { params });
  }

  findById(id: string): Observable<UserIface> {
    return this.http.get<UserIface>(`${this.url}/${id}`);
  }

  findLastHistoryStatus(userId: string): Observable<UserHistoryStatusInterface> {
    return this.http.get<UserHistoryStatusInterface>(`${this.url}/last-history-status/${userId}`);
  }

  findAllHistoryStatuses(userId: string): Observable<UserHistoryStatusInterface[]> {
    return this.http.get<UserHistoryStatusInterface[]>(`${this.url}/history-statuses/${userId}`);
  }

  create(user: UserIface): Observable<UserIface> {
    return this.http.post<UserIface>(this.url, user);
  }

  update(id: string, user: UserIface): Observable<UserIface> {
    return this.http.put<UserIface>(`${this.url}/${id}`, user);
  }

  count(query: any): Observable<Count> {
    const queryParams = new HttpParams().set('filter', JSON.stringify(query));
    return this.http.get<Count>(`${this.url}/count`, { params: queryParams });
  }

  remove(id: string): Observable<UserIface> {
    return this.http.delete<UserIface>(`${this.url}/${id}`);
  }

  setPassword(id, newPassword): Observable<UserIface> {
    return this.http.put<UserIface>(`${this.url}/setPassword/${id}`, { newPassword });
  }

  updatePassword({ id, oldPassword, password }): Observable<UserIface> {
    return this.http.put<UserIface>(`${this.url}/changePassword/${id}`, { oldPassword, newPassword: password });
  }

  unlock(id: string, data: ChangeUserStatusInterface<UnlockUserReasonEnum>) {
    return this.http.put<UserIface>(`${this.url}/unblock/${id}`, data);
  }

  block(id: string, data: ChangeUserStatusInterface<BlockUserReasonEnum>) {
    return this.http.put<UserIface>(`${this.url}/block/${id}`, data);
  }
}
