import { Injectable } from '@angular/core';
import { HttpClient, HttpParams } from '@angular/common/http';
import { ExportIface } from '../interfaces/export.iface';
import { environment } from '../../environments/environment';
import { Observable } from 'rxjs/internal/Observable';

@Injectable()
export class ExportService {
  constructor(private httpClient: HttpClient) {}

  findAll(filter: object, pageIndex: number, pageSize: number): Observable<ExportIface[]> {
    const params = new HttpParams()
      .set('filter', JSON.stringify(filter))
      .set('pageIndex', pageIndex.toString())
      .set('pageSize', pageSize.toString());
    return this.httpClient.get<ExportIface[]>(environment.EXPORT_API_URL, { params });
  }

  count(filter: object): Observable<number> {
    const params = new HttpParams().set('filter', JSON.stringify(filter));
    return this.httpClient.get<number>(`${environment.EXPORT_API_URL}/count`, { params });
  }

  reexport(previousExport: ExportIface): Observable<ExportIface> {
    return this.httpClient.post<ExportIface>(`${environment.EXPORT_API_URL}/reexport`, previousExport);
  }

  runExport(filter: object, filename: string, type: string): Observable<ExportIface> {
    const params = new HttpParams().set('type', type);
    return this.httpClient.post<ExportIface>(environment.EXPORT_API_URL, { filter, filename: filename }, { params });
  }
}
