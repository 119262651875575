import { AfterViewInit, Component, OnDestroy, OnInit, ViewChild } from '@angular/core';
import { MatDialog, MatPaginator } from '@angular/material';
import { RESTDataSource } from '../../../../helpers/rest.datasource';
import { UserGroupIface } from '../../../../interfaces/user-group.iface';
import { Subscription } from 'rxjs';
import { UserGroupsService } from '../../../../services/user-groups.service';
import { filter, switchMap, tap } from 'rxjs/operators';
import { UserService } from '../../../../services/user.service';
import { ToastrService } from 'ngx-toastr';
import { TableParametersIface } from '../../../../interfaces/tableParameters.iface';
import { TranslateService } from '@ngx-translate/core';
import { ConfirmDialogComponent } from '../../../../shared/components/confirm-dialog/confirm-dialog.component';

@Component({
  selector: 'app-user-group-list',
  templateUrl: './user-group-list.component.html',
  styleUrls: ['./user-group-list.component.scss']
})
export class UserGroupListComponent implements OnInit, AfterViewInit, OnDestroy {
  @ViewChild(MatPaginator)
  paginator: MatPaginator;
  public dataSource: RESTDataSource<UserGroupIface>;
  public displayedColumns = ['name', 'numberOfUsers', 'delete'];
  public isLoadingResults = false;
  private subscription: Subscription;

  constructor(
    private userGroupsService: UserGroupsService,
    private userService: UserService,
    private toastr: ToastrService,
    private translate: TranslateService,
    private matDialog: MatDialog
  ) {
    const browserLang = translate.currentLang;
    translate.use(browserLang.match(/en|pl/) ? browserLang : 'pl');
  }

  ngOnInit() {
    this.dataSource = new RESTDataSource(this.userGroupsService);
    this.subscription = this.dataSource.loading$.subscribe(state => {
      this.isLoadingResults = state;
    });
    this.paginator.pageSize = 10;
    this.loadData();
  }

  ngAfterViewInit() {
    this.paginator.page.pipe(tap(() => this.loadData())).subscribe();
  }

  ngOnDestroy() {
    this.subscription.unsubscribe();
  }

  loadData(): void {
    const query: TableParametersIface = {
      filters: {},
      pageIndex: this.paginator.pageIndex,
      pageSize: this.paginator.pageSize
    };
    this.dataSource.loadData(query);
  }

  deleteSegment(group: UserGroupIface, index: number, event: Event): void {
    event.stopPropagation();

    const dialogData = {
      title: 'DIALOG.CONFIRM.TITLE',
      message: 'DELETE_USER_GROUP_DIALOG.MESSAGE',
      translateParams: { group: group.name }
    };

    this.matDialog
      .open(ConfirmDialogComponent, { data: dialogData })
      .afterClosed()
      .pipe(
        filter(v => v),
        switchMap(() => this.userGroupsService.remove(group._id))
      )
      .subscribe(
        () => {
          this.loadData();
          this.toastr.info(this.translate.instant('DELETE_USER_GROUP_DIALOG.SUCCESS'));
        },
        () => {
          this.toastr.error(
            this.translate.instant('DELETE_USER_GROUP_DIALOG.ERROR_TITLE'),
            this.translate.instant('DELETE_USER_GROUP_DIALOG.ERROR_MESSAGE')
          );
        }
      );
  }
}
