import { Component, EventEmitter, OnInit, Output } from '@angular/core';
import { FormControl, FormGroup, ValidationErrors, ValidatorFn, Validators } from '@angular/forms';
import { ToastrService } from 'ngx-toastr';
import { AuthService } from '../../../services/auth.service';
import { ReCaptchaV3Service } from 'ng-recaptcha';
import { catchError, mergeMap, tap } from 'rxjs/operators';

@Component({
  selector: 'app-change-password-form',
  templateUrl: './change-password-form.component.html',
  styleUrls: ['./change-password-form.component.scss']
})
export class ChangePasswordFormComponent implements OnInit {
  @Output() isPasswordChagned: EventEmitter<boolean> = new EventEmitter<boolean>();

  public changePasswordForm: FormGroup;
  public hideNewPassword = true;
  public hideOldPassword = true;
  public validationMessage: string;

  private passwordRegex: RegExp = /^(?=.*[a-z])(?=.*[A-Z])(?=.*\d)(?=.*[#$^+=!*()@%&]).{10,}$/;

  constructor(
    private authService: AuthService,
    private toastr: ToastrService,
    private recaptchaV3Service: ReCaptchaV3Service
  ) {}

  ngOnInit(): void {
    this.createChangePasswordForm();
    this.changePasswordForm.markAsTouched();
  }

  createChangePasswordForm(): void {
    this.changePasswordForm = new FormGroup(
      {
        username: new FormControl('', Validators.required),
        oldPassword: new FormControl('', Validators.required),
        newPassword: new FormControl(
          '',
          Validators.compose([Validators.required, Validators.pattern(this.passwordRegex)])
        )
      },
      {
        validators: [this.verifyPassword()]
      }
    );
  }

  verifyPassword(): ValidatorFn {
    return (formGroup: FormGroup): ValidationErrors | null => {
      const oldPassword = formGroup.controls['oldPassword'];
      const newPassword = formGroup.controls['newPassword'];
      if (newPassword.value !== '') {
        formGroup.get('newPassword').markAsTouched();
      }
      if (newPassword.errors && !newPassword.errors.passwordNotMatch) {
        return null;
      }
      if (oldPassword.value === newPassword.value) {
        newPassword.setErrors({ passwordNotMatch: true });
        return { passwordNotMatch: true };
      } else {
        newPassword.setErrors(null);
        return null;
      }
    };
  }

  changePassword(form: FormGroup): void {
    this.validationMessage = null;

    if (!form.valid) {
      return;
    }

    this.changePasswordForm.disable();

    this.recaptchaV3Service
      .execute('importantAction')
      .pipe(
        mergeMap(recaptchaToken => {
          const changePasswordDto = form.getRawValue();
          changePasswordDto.recaptchaToken = recaptchaToken;
          return this.authService.changePasswordByUsername(changePasswordDto);
        }),
        tap(() => this.changePasswordForm.enable()),
        catchError(err => {
          this.changePasswordForm.enable();

          switch (err.status) {
            case 405:
              this.validationMessage = 'Konto jest zablokowane. Skontaktuj się z administratorem.';
              break;
            case 403:
              this.toastr.error('Błędny login lub hasło!');
              break;
            case 400:
              this.toastr.error('Weryfikacja tokenu captcha nie powiodła się!');
              break;
            default:
              this.toastr.error('Wystąpił błąd');
          }

          throw err;
        })
      )
      .subscribe(res => {
        this.changePasswordForm.reset();
        this.isPasswordChagned.emit(true);
        this.toastr.success('Hasło zostało zmienione!');
      });
  }
}
