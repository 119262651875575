import { Component, Inject, OnInit } from '@angular/core';
import { UserIface } from '../../../../interfaces/user.iface';
import { MAT_DIALOG_DATA, MatDialogRef } from '@angular/material';
import { FormBuilder, FormControl, FormGroup, Validators } from '@angular/forms';
import { TranslateService } from '@ngx-translate/core';
import { PasswordGenerator } from '../../../../../../src/app/helpers/password-generator';

@Component({
  selector: 'app-set-password-dialog',
  templateUrl: './set-password-dialog.component.html',
  styleUrls: ['./set-password-dialog.component.scss']
})
export class SetPasswordDialogComponent implements OnInit {
  public user: UserIface;
  public setPasswordForm: FormGroup;

  constructor(
    @Inject(MAT_DIALOG_DATA) public data: UserIface,
    private formBuilder: FormBuilder,
    private dialogRef: MatDialogRef<SetPasswordDialogComponent>,
    private translate: TranslateService
  ) {}

  ngOnInit(): void {
    this.translateInit();
    this.createForm();
  }

  createForm(): void {
    this.user = this.data;
    this.setPasswordForm = this.formBuilder.group({
      password: new FormControl('', Validators.required)
    });
  }

  translateInit(): void {
    const browserLang = this.translate.currentLang;
    this.translate.use(browserLang.match(/en|pl/) ? browserLang : 'pl');
  }

  generatePassword(): void {
    const generatePass = PasswordGenerator.create();
    this.setPasswordForm.controls['password'].setValue(generatePass);
  }

  setPassword(form: FormGroup) {
    this.dialogRef.close(form.value.password);
  }

  copyMessage(val: string) {
    const selBox = document.createElement('textarea');
    selBox.style.position = 'fixed';
    selBox.style.left = '0';
    selBox.style.top = '0';
    selBox.style.opacity = '0';
    selBox.value = val;
    document.body.appendChild(selBox);
    selBox.focus();
    selBox.select();
    document.execCommand('copy');
    document.body.removeChild(selBox);
  }
}
