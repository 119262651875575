export class FileNode {
  id: string;
  parentId: string;
  filename: string;
  level: number;
  children: FileNode[];
  type: string;
}

export class FileFlatNode {
  expandable: boolean;
  filename: string;
  level: number;
  type: any;
  id: string;
}
