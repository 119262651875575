import { NgModule } from '@angular/core';
import { ExportLogsComponent } from './export-logs.component';
import { MaterialModule } from '../material/material.module';
import { CommonModule } from '@angular/common';
import { FormsModule, ReactiveFormsModule } from '@angular/forms';
import { RouterModule } from '@angular/router';
import { ExportLogsDialogComponent } from './export-logs-dialog/export-logs-dialog.component';
import { TranslateModule } from '@ngx-translate/core';

@NgModule({
  imports: [MaterialModule, CommonModule, FormsModule, ReactiveFormsModule, RouterModule, TranslateModule],
  declarations: [ExportLogsComponent, ExportLogsDialogComponent],
  entryComponents: [ExportLogsDialogComponent]
})
export class ExportLogsModule {}
