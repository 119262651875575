export class PasswordGenerator {
  static create() {
    const length = 3;
    const characters = 'abcdefghijklmnopqrstuvwxyz';
    const specialCharacters = '!@#%()';
    const number = '0123456789';
    const charactersBig = 'ABCDEFGHIJKLMNOPQRSTUVWXYZ';
    let password = '';
    let generatePassword = '';

    for (var i = 0; i < length; ++i) {
      password += characters.charAt(Math.floor(Math.random() * characters.length));
      password += specialCharacters.charAt(Math.floor(Math.random() * specialCharacters.length));
      password += number.charAt(Math.floor(Math.random() * number.length));
      password += charactersBig.charAt(Math.floor(Math.random() * charactersBig.length));
    }

    let newPassword = this.shuffle(password.split(''));

    for (var j = 0; j < password.length; j++) {
      generatePassword += newPassword.next().value;
    }
    return password;
  }

  static *shuffle(array) {
    var i = array.length;
    while (i--) {
      yield array.splice(Math.floor(Math.random() * (i + 1)), 1)[0];
    }
  }
}
