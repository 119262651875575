import { CredentialsIface } from './credentials.iface';

export class Credentials implements CredentialsIface {
  constructor(
    public username: string,
    public password: string,
    public grantType: string,
    public recaptchaToken: string
  ) {}
}
