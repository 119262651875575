import { Injectable } from '@angular/core';
import { environment } from '../../environments/environment';
import { HttpClient } from '@angular/common/http';
import { Observable } from 'rxjs';
import { HierarchyIface } from '../interfaces/hierarchy.iface';

@Injectable()
export class HierarchyService {
  private readonly url = `${environment.USERS_HIERARCHY_API_URL}`;
  public hierarchyId;

  constructor(private http: HttpClient) {}

  fetch(): Observable<HierarchyIface> {
    return this.http.get<HierarchyIface>(this.url);
  }

  update(hierarchy: HierarchyIface): Observable<HierarchyIface> {
    if (this.hierarchyId) {
      return this.http.put<HierarchyIface>(`${this.url}/${this.hierarchyId}`, hierarchy);
    }
    return this.create(hierarchy);
  }

  private create(hierarchy: HierarchyIface): Observable<HierarchyIface> {
    return this.http.post<HierarchyIface>(this.url, hierarchy);
  }
}
