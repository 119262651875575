import { Component, Input } from '@angular/core';
import { FormControl, FormGroup, Validators } from '@angular/forms';
import { AuthService } from '../../../services/auth.service';
import { Credentials } from '../../../interfaces/credentials';
import { UserIface } from '../../../interfaces/user.iface';
import { CookieService } from 'ng2-cookies';
import { Router } from '@angular/router';
import { LoggerService } from '../../../services/logger.service';
import { AuthQrantTypeEnum } from '../../../enums/authQrantType.enum';
import { ToastrService } from 'ngx-toastr';
import { EventsService } from '../../../services/events.service';
import { UserModel } from '../../../models/user.model';
import { TranslateService } from '@ngx-translate/core';
import { catchError, filter, mergeMap } from 'rxjs/operators';
import { ReCaptchaV3Service } from 'ng-recaptcha';

@Component({
  selector: 'app-authorization-form',
  templateUrl: './authorization-form.component.html',
  styleUrls: ['./authorization-form.component.scss']
})
export class AuthorizationFormComponent {
  @Input() public qrantType: string;
  public authorizationForm: FormGroup;
  public qrantTypeEnum = AuthQrantTypeEnum;
  public validationMessage: string;

  constructor(
    private authService: AuthService,
    private router: Router,
    private toastr: ToastrService,
    private cookieService: CookieService,
    private events: EventsService,
    private translate: TranslateService,
    private recaptchaV3Service: ReCaptchaV3Service
  ) {
    const browserLang = translate.currentLang;
    translate.use(browserLang.match(/en|pl/) ? browserLang : 'pl');
    this.authorizationForm = new FormGroup({
      username: new FormControl('', Validators.required),
      password: new FormControl('', Validators.required)
    });
  }

  signIn(form: FormGroup): void {
    this.authorizationForm.disable();

    this.recaptchaV3Service
      .execute('importantAction')
      .pipe(
        mergeMap(recaptchaToken => {
          const credentials = new Credentials(form.value.username, form.value.password, this.qrantType, recaptchaToken);
          return this.authService.signIn(credentials);
        }),
        filter((res: UserIface) => {
          this.authorizationForm.enable();

          this.validationMessage = res.isBlocked ? 'Konto jest zablokowane. Skontaktuj się z administratorem' : null;
          return !res.isBlocked;
        }),
        catchError(err => {
          LoggerService.log('AuthorizationFormComponent::singIn', JSON.stringify(err));
          this.authorizationForm.enable();

          switch (err.status) {
            case 409:
              this.toastr.error('Hasło wygasło! Zmień hasło.');
              break;
            case 405:
              this.validationMessage = 'Konto jest zablokowane. Skontaktuj się z administratorem';
              break;
            case 403:
              this.toastr.error('Błędny login lub hasło!');
              break;
            case 400:
              this.toastr.error('Weryfikacja tokenu captcha nie powiodła się!');
              break;
            default:
              this.toastr.error('Wystąpił błąd');
          }

          throw err;
        })
      )
      .subscribe((res: UserIface) => {
        const { token, ...user } = res;
        this.authService.token = token;
        this.cookieService.set('token', token);
        this.authService.user = user;
        this.router.navigate(['account']);
        this.sendUserInfoEvent(new UserModel(user));
      });
  }

  sendUserInfoEvent(user: UserModel): void {
    this.authService.getIpAddress().subscribe(
      data => {
        const event = this.events.getFetchUserEvent(user, data.ip);
        this.events.createEvent(event).subscribe();
      },
      err => {
        console.log(err);
      }
    );
  }
}
