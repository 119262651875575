import { Component, ViewChild, OnInit, AfterViewInit, NgZone, ElementRef } from '@angular/core';
import { UserIface } from '../../../interfaces/user.iface';
import { AuthService } from '../../../services/auth.service';
import { UserService } from '../../../services/user.service';
import { MatDialog, MatPaginator } from '@angular/material';
import { EditRolesDialogComponent } from './edit-roles-dialog/edit-roles-dialog.component';
import { SetPasswordDialogComponent } from './set-password-dialog/set-password-dialog.component';
import { BlockUserDialogComponent } from './block-user-dialog/block-user-dialog.component';
import { ToastrService } from 'ngx-toastr';
import { RolesEnum } from '../../../enums/roles.enum';
import { EditMetadataDialogComponent } from './edit-metadata-dialog/edit-metadata-dialog.component';
import { TranslateService } from '@ngx-translate/core';
import { RESTDataSource } from '../../../helpers/rest.datasource';
import { Subscription, fromEvent } from 'rxjs';
import { debounceTime, distinctUntilChanged, filter, map, tap } from 'rxjs/operators';
import { ConfirmDialogComponent } from '../../../shared/components/confirm-dialog/confirm-dialog.component';
import { ConfirmDialogInterface } from '../../../shared/interfaces/confirm-dialog.interface';
import { UnlockUserDialogComponent } from './unlock-user-dialog/unlock-user-dialog.component';
import { ChangeUserStatusInterface } from '../../../interfaces/change-user-status.interface';
import { AddSalesmanCodeDialogComponent } from './add-salesman-code-dialog/add-salesman-code-dialog.component';
import { AddSellerAccountIdDialogComponent } from './add-seller-account-id-dialog/add-seller-account-id-dialog.component';

enum EditTypeEnum {
  EDIT_ROLES = 'EDIT_ROLES',
  EDIT_METADATA = 'EDIT_METADATA',
  ADD_SALESMAN_CODE = 'ADD_SALESMAN_CODE',
  ADD_SELLER_ACCOUNT_ID = 'ADD_SELLER_ACCOUNT_ID'
}

@Component({
  selector: 'app-user-list',
  templateUrl: './user-list.component.html',
  styleUrls: ['./user-list.component.scss']
})
export class UserListComponent implements OnInit, AfterViewInit {
  @ViewChild(MatPaginator)
  public paginator: MatPaginator;
  @ViewChild('filterRef')
  filterRef: ElementRef;
  public user: UserIface;
  public users: UserIface[];
  public editTypeEnum = EditTypeEnum;
  displayedColumns: string[] = [
    'username',
    'roles',
    'salesmanCode',
    'sellerAccountId',
    'metadata',
    'password',
    'toggleBlockUser',
    'delete'
  ];
  public dataSource: RESTDataSource<any>;
  public subscription: Subscription;
  public isLoadingResults = false;
  private filterName = '';

  constructor(
    private authService: AuthService,
    private userService: UserService,
    private matDialog: MatDialog,
    private toastr: ToastrService,
    private translate: TranslateService,
    private zone: NgZone
  ) {
    const browserLang = translate.currentLang;
    translate.use(browserLang.match(/en|pl/) ? browserLang : 'pl');
    this.user = this.authService.user;
    this.userService.findAll({}, 0, 0).subscribe(allUsers => {
      this.users = allUsers;
    });
  }

  ngOnInit() {
    this.dataSource = new RESTDataSource(this.userService);
    this.subscription = this.dataSource.loading$.subscribe(state => {
      this.isLoadingResults = state;
    });
    this.paginator.pageSize = 10;
    this.loadData();
    fromEvent(this.filterRef.nativeElement, 'keyup')
      .pipe(
        map((e: any) => e.target.value),
        debounceTime(500),
        distinctUntilChanged()
      )
      .subscribe((text: string) => {
        this.filterName = text;
        this.filterData(text);
      });
  }

  ngAfterViewInit() {
    this.paginator.page
      .pipe(
        tap(() => {
          if (this.filterName) {
            this.filterData(this.filterName);
          } else {
            this.loadData();
          }
        })
      )
      .subscribe();
  }

  loadData(): void {
    const query = {};
    this.dataSource.loadUsersData(query, this.paginator.pageIndex, this.paginator.pageSize);
  }

  public toggleUser(user: UserIface): void {
    const selectedDialog = user.isBlocked ? UnlockUserDialogComponent : BlockUserDialogComponent;
    const dialogData: ConfirmDialogInterface<UserIface> = {
      title: 'DIALOG.CONFIRM.TITLE',
      message: `${user.isBlocked ? 'UNLOCK_USER_DIALOG' : 'BLOCK_USER_DIALOG'}.MESSAGE`,
      translateParams: { username: user.username },
      customData: user
    };

    this.matDialog
      .open<UnlockUserDialogComponent | BlockUserDialogComponent>(selectedDialog, { data: dialogData })
      .afterClosed()
      .pipe(filter(v => v))
      .subscribe((data: ChangeUserStatusInterface) => {
        if (user.isBlocked) {
          this.userService.unlock(user._id, data).subscribe(
            () => {
              this.dataSource.setDataSourceValueByProp('_id', user._id, 'isBlocked', false);
              this.toastr.info(`${this.translate.instant('SNACK_MESSAGE.USER_UNLOCKED')}`);
            },
            () => {
              this.toastr.error(`${this.translate.instant('SNACK_MESSAGE.USER_UNLOCKED_ERROR')}`);
            }
          );
        } else {
          this.userService.block(user._id, data).subscribe(
            () => {
              this.dataSource.setDataSourceValueByProp('_id', user._id, 'isBlocked', true);
              this.toastr.info(`${this.translate.instant('SNACK_MESSAGE.USER_BLOCKED')}`);
            },
            () => {
              this.toastr.error(`${this.translate.instant('SNACK_MESSAGE.USER_BLOCKED_ERROR')}`);
            }
          );
        }
      });
  }

  public deleteUser(user: UserIface): void {
    const dialogData: ConfirmDialogInterface = {
      title: 'DIALOG.CONFIRM.TITLE',
      message: `CONFIRM_DELETE.MESSAGE`,
      translateParams: { username: user.username }
    };

    this.matDialog
      .open(ConfirmDialogComponent, { data: dialogData })
      .afterClosed()
      .pipe(filter(v => v))
      .subscribe(() => {
        this.userService.remove(user._id).subscribe(
          () => {
            this.dataSource.removeListItemByPropertyValue('_id', user._id);
            this.toastr.info(`${this.translate.instant('SNACK_MESSAGE.USER_REMOVED')}`);
          },
          () => {
            this.toastr.error(`${this.translate.instant('SNACK_MESSAGE.USER_REMOVED_ERROR')}`);
          }
        );
      });
  }

  public changePassword(user: UserIface): void {
    this.matDialog
      .open(SetPasswordDialogComponent, { data: user })
      .afterClosed()
      .subscribe(newPassword => {
        if (newPassword) {
          this.userService.setPassword(user._id, newPassword).subscribe(
            () => {
              this.toastr.info('Hasło zostało zmienione');
            },
            () => {
              this.toastr.error('Hasło nie zostało zmienione');
            }
          );
        }
      });
  }

  public editUser(user: UserIface, editType: EditTypeEnum = EditTypeEnum.EDIT_ROLES): void {
    let dialogType;
    switch (editType) {
      case EditTypeEnum.EDIT_ROLES:
        dialogType = EditRolesDialogComponent;
        break;
      case EditTypeEnum.ADD_SALESMAN_CODE:
        dialogType = AddSalesmanCodeDialogComponent;
        break;
      case EditTypeEnum.ADD_SELLER_ACCOUNT_ID:
        dialogType = AddSellerAccountIdDialogComponent;
        break;
      case EditTypeEnum.EDIT_METADATA:
        dialogType = EditMetadataDialogComponent;
    }
    this.matDialog
      .open(dialogType, { data: user })
      .afterClosed()
      .subscribe(updatedUser => {
        if (updatedUser) {
          this.userService.update(user._id || user.userId, updatedUser).subscribe(
            () => {
              user.roles = updatedUser.roles;
              user.metadata = updatedUser.metadata;
              this.toastr.info('Użytkownik został zaktualizowany');
            },
            () => {
              this.toastr.error('Nie udało się zaktualizować użytkownika');
            }
          );
        }
      });
  }

  public isCurrentUserAdmin(): boolean {
    return this.user.roles.includes(RolesEnum.ADMIN);
  }

  filterData(username: string): void {
    this.zone.run(() => {
      this.dataSource.loadUsersData(
        { username: { $regex: `${username}`, $options: 'i' } },
        this.paginator.pageIndex,
        this.paginator.pageSize
      );
    });
  }
}
