import { Component, OnInit } from '@angular/core';
import { AuthQrantTypeEnum } from '../../enums/authQrantType.enum';
import { ActivatedRoute } from '@angular/router';
import { ToastrService } from 'ngx-toastr';
import { filter } from 'rxjs/operators';
import { AuthFromTypeEnum } from '../../enums/authFromType.enum';

@Component({
  selector: 'app-sign-in',
  templateUrl: './sign-in.component.html',
  styleUrls: ['./sign-in.component.scss']
})
export class SignInComponent implements OnInit {
  public qrantTypeEnum = AuthQrantTypeEnum;
  public formTypeEnum = AuthFromTypeEnum;
  public formType = AuthFromTypeEnum.LOGIN;
  public checkGrantType = AuthQrantTypeEnum.LDAP;
  public tabIndex: number = 1;

  constructor(private route: ActivatedRoute, private toastr: ToastrService) {}

  ngOnInit() {
    this.route.queryParams.pipe(filter(params => params.sessionExpired)).subscribe(params => {
      if (params) {
        setTimeout(() => {
          this.toastr.warning('Zaloguj się ponownie', 'Sesja wygasła!');
        }, 0);
      }
    });
  }

  isPasswordChanged(isPasswordChanged: boolean): void {
    if (isPasswordChanged) {
      this.formType = AuthFromTypeEnum.LOGIN;
    } else {
      this.formType = AuthFromTypeEnum.CHANGE_PASSWORD;
    }
  }

  changeFromType(): void {
    if (this.formType === AuthFromTypeEnum.LOGIN) {
      this.formType = AuthFromTypeEnum.CHANGE_PASSWORD;
    } else {
      this.formType = AuthFromTypeEnum.LOGIN;
    }
  }
}
