import { AfterViewInit, ChangeDetectorRef, Component, OnDestroy, OnInit, ViewChild } from '@angular/core';
import { MatDialog, MatPaginator } from '@angular/material';
import { ExportLogsDialogComponent } from './export-logs-dialog/export-logs-dialog.component';
import { JobStatusEnum } from '../../enums/job-status.enum';
import { ExportIface } from '../../interfaces/export.iface';
import { RESTDataSource } from '../../helpers/rest.datasource';
import { tap } from 'rxjs/operators';
import { Subscription } from 'rxjs/internal/Subscription';
import { ExportService } from '../../services/export.service';
import { AuthService } from '../../services/auth.service';
import { UserModel } from '../../models/user.model';
import { ToastrService } from 'ngx-toastr';
import { DownloadLogsService } from '../../services/download-logs.service';
import { TableParametersIface } from '../../interfaces/tableParameters.iface';
import { TranslateService } from '@ngx-translate/core';

@Component({
  selector: 'app-export-logs',
  templateUrl: './export-logs.component.html',
  styleUrls: ['./export-logs.component.scss']
})
export class ExportLogsComponent implements OnInit, AfterViewInit, OnDestroy {
  private static readonly EXPORTED_LOGS_DIRECTORY = 'exported-logs';
  private static readonly EXPORT_TYPE = 'LOGS_EXPORT';

  private subscriptions: Subscription = new Subscription();

  @ViewChild(MatPaginator)
  public paginator: MatPaginator;
  public isLoadingResults = false;
  public dataSource: RESTDataSource<ExportIface>;
  public user: UserModel;
  public displayedColumns = ['date', 'file', 'status', 'reexport'];
  public jobStatusEnum = JobStatusEnum;

  constructor(
    private authService: AuthService,
    private matDialog: MatDialog,
    private exportService: ExportService,
    private toastr: ToastrService,
    private downloadLogsService: DownloadLogsService,
    private translate: TranslateService
  ) {
    const browserLang = translate.currentLang;
    translate.use(browserLang.match(/en|pl/) ? browserLang : 'pl');
    this.user = this.authService.userModel.getValue();
  }

  ngOnInit() {
    this.dataSource = new RESTDataSource(this.exportService);
    this.subscriptions.add(
      this.dataSource.loading$.subscribe(state => {
        this.isLoadingResults = state;
      })
    );
    this.paginator.pageSize = 10;
    this.loadData();
  }

  ngAfterViewInit() {
    this.paginator.page.pipe(tap(() => this.loadData())).subscribe();
  }

  ngOnDestroy() {
    this.subscriptions.unsubscribe();
  }

  exportLogs(): void {
    this.matDialog
      .open(ExportLogsDialogComponent)
      .afterClosed()
      .subscribe(
        () => {
          this.paginator.pageIndex = 0;
          this.loadData();
        },
        () => {
          this.toastr.error('Nie udało się rozpocząć generowania pliku', 'Wystapił bląd');
        }
      );
  }

  reexportLogs(previousExport: ExportIface): void {
    this.exportService.reexport(previousExport).subscribe(
      () => {
        this.toastr.info('Prosimy o cierpliwość', 'Dane pomyślnie przekazane do wygenerowania raportu!');
        this.paginator.pageIndex = 0;
        this.loadData();
      },
      () => {
        this.toastr.error('Nie udało się rozpocząć generowania pliku', 'Wystapił bląd');
      }
    );
  }

  downloadFile(filename: string): void {
    this.downloadLogsService.downloadFile(ExportLogsComponent.EXPORTED_LOGS_DIRECTORY, filename);
  }

  private loadData(): void {
    const query: TableParametersIface = {
      filters: { 'user.username': this.user.get('username'), type: ExportLogsComponent.EXPORT_TYPE },
      pageIndex: this.paginator.pageIndex,
      pageSize: this.paginator.pageSize,
      sortActive: 'timestamp',
      sortDirection: '-1'
    };
    this.dataSource.loadData(query);
  }
}
