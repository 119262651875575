import { Component, Inject, OnInit } from '@angular/core';
import { MAT_DIALOG_DATA, MatDialogRef } from '@angular/material';
import { TranslateService } from '@ngx-translate/core';
import { ConfirmDialogInterface } from '../../interfaces/confirm-dialog.interface';

@Component({
  selector: 'app-confirm-dialog',
  templateUrl: './confirm-dialog.component.html',
  styleUrls: ['./confirm-dialog.component.scss']
})
export class ConfirmDialogComponent implements OnInit {
  constructor(
    @Inject(MAT_DIALOG_DATA) public data: ConfirmDialogInterface,
    private refDialog: MatDialogRef<ConfirmDialogComponent>,
    private translate: TranslateService
  ) {}

  ngOnInit() {}

  confirm() {
    this.refDialog.close(true);
  }
}
