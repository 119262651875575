import { Injectable } from '@angular/core';
import { environment } from '../../environments/environment';

@Injectable()
export class LoggerService {
  public static log(text: string, data: any = '') {
    if (!environment.production) {
      console.log(text, data);
    }
  }
}
