import { Component, Inject, OnInit } from '@angular/core';
import { FormControl, FormGroup, Validators } from '@angular/forms';
import { MAT_DIALOG_DATA, MatDialogRef } from '@angular/material';
import { UserIface } from '../../../../interfaces/user.iface';

@Component({
  selector: 'app-add-salesman-code-dialog',
  templateUrl: './add-salesman-code-dialog.component.html',
  styleUrls: ['./add-salesman-code-dialog.component.scss']
})
export class AddSalesmanCodeDialogComponent implements OnInit {
  public form: FormGroup;

  constructor(
    @Inject(MAT_DIALOG_DATA) public data: UserIface,
    private refDialog: MatDialogRef<AddSalesmanCodeDialogComponent>
  ) {}

  ngOnInit() {
    this.createForm();
  }

  submit(): void {
    if (this.form.valid) {
      const salesmanCode = this.form.get('salesmanCode').value;
      this.data.metadata
        ? (this.data.metadata['salesmanCode'] = salesmanCode)
        : (this.data = { ...this.data, metadata: { salesmanCode } });
      this.refDialog.close(this.data);
    }
  }

  private createForm(): void {
    this.form = new FormGroup({
      salesmanCode: new FormControl('', Validators.required)
    });
  }
}
