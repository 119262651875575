import { FormBuilder, FormGroup } from '@angular/forms';
import { MatDialogRef } from '@angular/material';
import { TranslateService } from '@ngx-translate/core';
import { ChangeUserStatusInterface } from '../../../../interfaces/change-user-status.interface';

export abstract class ToggleUserBaseComponent<T> {
  form: FormGroup;
  selectOptions: { name: string; value: number }[];

  constructor(
    protected refDialog: MatDialogRef<T>,
    protected formBuilder: FormBuilder,
    protected translate: TranslateService
  ) {
    const browserLang = this.translate.currentLang;
    this.translate.use(browserLang.match(/en|pl/) ? browserLang : 'pl');

    this.form = this.setupForm();
  }

  submitForm(data: ChangeUserStatusInterface, formValid: boolean) {
    if (!formValid) {
      return;
    }

    this.refDialog.close(data);
  }

  protected optionsFromEnum<Y>(enumValue: Y, translatePath: string): { name: string; value: number }[] {
    const valuesFromEnum = Object.values(enumValue);

    return valuesFromEnum
      .filter((v, index) => index < valuesFromEnum.length / 2)
      .map((value: string, index: number) => {
        return {
          name: `${translatePath}.${value}`,
          value: index
        };
      });
  }

  protected abstract setupForm(): FormGroup;
}
