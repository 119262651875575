import { Injectable } from '@angular/core';
import { environment } from '../../environments/environment';
import { HttpClient, HttpParams } from '@angular/common/http';
import { Observable } from 'rxjs';
import { UserDataLimitIface } from '../interfaces/user-data-limit.iface';

@Injectable()
export class UsersDataLimitService {
  private readonly url = `${environment.DATA_LIMIT}`;

  constructor(private http: HttpClient) {}

  changeLimit(allowedAmount: UserDataLimitIface, id: any): Observable<any> {
    return this.http.put<any>(`${this.url}/${id}`, allowedAmount);
  }

  getId() {
    const queryParams = new HttpParams().set('name', 'searching_blocker');
    return this.http.get<any>(this.url, { params: queryParams });
  }
}
