import { SelectionModel } from '@angular/cdk/collections';
import { FlatTreeControl } from '@angular/cdk/tree';
import { Component, ElementRef, ViewChild } from '@angular/core';
import { MatDialog, MatTreeFlatDataSource, MatTreeFlattener } from '@angular/material';
import { TranslateService } from '@ngx-translate/core';
import { ToastrService } from 'ngx-toastr';
import { HierarchyService } from '../../../services/hierarchy.service';
import { AddUserDialogComponent } from './add-user-dialog/add-user-dialog.component';
import { AddUserHierarchyDialogComponent } from './add-user-hierarchy-dialog/add-user-hierarchy-dialog.component';
import { FileFlatNode, FileNode } from './user-hierarchy-filenode';
import { FileDatabase } from './users-hierarchy-database';

@Component({
  selector: 'app-users-hierarchy',
  templateUrl: './users-hierarchy.component.html',
  styleUrls: ['./users-hierarchy.component.scss'],
  providers: [FileDatabase]
})
export class UsersHierarchyComponent {
  @ViewChild('emptyItem') emptyItem: ElementRef;

  flatNodeMap = new Map<FileFlatNode, FileNode>();
  nestedNodeMap = new Map<FileNode, FileFlatNode>();
  selectedParent: FileFlatNode | null = null;
  newItemName = '';
  treeControl: FlatTreeControl<FileFlatNode>;
  treeFlattener: MatTreeFlattener<FileNode, FileFlatNode>;
  dataSource: MatTreeFlatDataSource<FileNode, FileFlatNode>;
  checklistSelection = new SelectionModel<FileFlatNode>(true);

  dragNode: any;
  dragNodeExpandOverWaitTimeMs = 300;
  dragNodeExpandOverNode: any;
  dragNodeExpandOverTime: number;
  dragNodeExpandOverArea: number;

  constructor(
    private database: FileDatabase,
    private dialog: MatDialog,
    private toastr: ToastrService,
    private hierarchyService: HierarchyService,
    private translate: TranslateService
  ) {
    const browserLang = translate.currentLang;
    translate.use(browserLang.match(/en|pl/) ? browserLang : 'pl');

    this.treeFlattener = new MatTreeFlattener(this.transformer, this.getLevel, this.isExpandable, this.getChildren);
    this.treeControl = new FlatTreeControl<FileFlatNode>(this.getLevel, this.isExpandable);
    this.dataSource = new MatTreeFlatDataSource(this.treeControl, this.treeFlattener);

    database.dataChange.subscribe(data => {
      this.dataSource.data = [];
      this.dataSource.data = data;
    });
  }

  getLevel = (node: FileFlatNode) => node.level;
  isExpandable = (node: FileFlatNode) => node.expandable;
  getChildren = (node: FileNode): FileNode[] => node.children;
  hasChild = (_: number, _nodeData: FileFlatNode) => _nodeData.expandable;
  hasNoContent = (_: number, _nodeData: FileFlatNode) => _nodeData.filename === '';

  transformer = (node: FileNode, level: number) => {
    const existingNode = this.nestedNodeMap.get(node);
    const flatNode = existingNode && existingNode.filename === node.filename ? existingNode : new FileFlatNode();
    flatNode.filename = node.filename;
    flatNode.level = level;
    flatNode.expandable = node.children && node.children.length > 0;
    this.flatNodeMap.set(flatNode, node);
    this.nestedNodeMap.set(node, flatNode);
    return flatNode;
  };

  descendantsAllSelected(node: FileFlatNode): boolean {
    const descendants = this.treeControl.getDescendants(node);
    return descendants.every(child => this.checklistSelection.isSelected(child));
  }

  descendantsPartiallySelected(node: FileFlatNode): boolean {
    const descendants = this.treeControl.getDescendants(node);
    const result = descendants.some(child => this.checklistSelection.isSelected(child));
    return result && !this.descendantsAllSelected(node);
  }

  todoItemSelectionToggle(node: FileFlatNode): void {
    this.checklistSelection.toggle(node);
    const descendants = this.treeControl.getDescendants(node);
    this.checklistSelection.isSelected(node)
      ? this.checklistSelection.select(...descendants)
      : this.checklistSelection.deselect(...descendants);
  }

  addNewItem(node: FileFlatNode) {
    const parentNode = this.flatNodeMap.get(node);
    const dialogRef = this.dialog.open(AddUserHierarchyDialogComponent, {
      width: '500px',
      height: '500px'
    });

    dialogRef.afterClosed().subscribe((res: string[]) => {
      if (!res) {
        return;
      }
      res.forEach(name => {
        this.database.insertItem(parentNode, name);
      });
      this.treeControl.expand(node);
    });
  }

  addNewItemWithoutTree() {
    const dialogRef = this.dialog.open(AddUserDialogComponent, {
      width: '500px',
      height: '600px'
    });

    dialogRef.afterClosed().subscribe((res: any) => {
      if (!res) {
        return;
      }
      const nameBoss = res.namesBoss[0];
      res.namesUser.forEach(name => {
        this.flatNodeMap.forEach(node => {
          const child = node.children.find(ch => ch.filename === nameBoss);
          if (child) {
            this.database.insertItem(child, name);
          }
        });
      });
      this.toastr.success('Dodano rekordy do hierarchii. Kliknij przycisk Zapisz!');
    });
  }

  expandToggle(type: string): void {
    if (type === 'collapseAll') {
      this.treeControl.collapseAll();
    } else {
      this.treeControl.expandAll();
    }
  }

  saveNode(node: FileFlatNode, itemValue: string) {
    const nestedNode = this.flatNodeMap.get(node);
    this.database.updateItem(nestedNode, itemValue);
  }

  handleDragStart(event, node) {
    event.dataTransfer.setData('foo', 'bar');
    this.dragNode = node;
    this.treeControl.collapse(node);
  }

  handleDragOver(event, node) {
    event.preventDefault();
    if (this.dragNodeExpandOverNode && node === this.dragNodeExpandOverNode) {
      if (Date.now() - this.dragNodeExpandOverTime > this.dragNodeExpandOverWaitTimeMs) {
        if (!this.treeControl.isExpanded(node)) {
          this.treeControl.expand(node);
        }
      }
    } else {
      this.dragNodeExpandOverNode = node;
      this.dragNodeExpandOverTime = new Date().getTime();
    }

    const percentageY = event.offsetY / event.target.clientHeight;
    if (0 <= percentageY && percentageY <= 0.25) {
      this.dragNodeExpandOverArea = 1;
    } else if (1 >= percentageY && percentageY >= 0.75) {
      this.dragNodeExpandOverArea = -1;
    } else {
      this.dragNodeExpandOverArea = 0;
    }
  }

  handleDrop(event, node) {
    if (node !== this.dragNode) {
      let newItem: FileNode;
      if (this.dragNodeExpandOverArea === 1) {
        newItem = this.database.copyPasteItemAbove(this.flatNodeMap.get(this.dragNode), this.flatNodeMap.get(node));
      } else if (this.dragNodeExpandOverArea === -1) {
        newItem = this.database.copyPasteItemBelow(this.flatNodeMap.get(this.dragNode), this.flatNodeMap.get(node));
      } else {
        newItem = this.database.copyPasteItem(this.flatNodeMap.get(this.dragNode), this.flatNodeMap.get(node));
      }
      this.database.deleteItem(this.flatNodeMap.get(this.dragNode));
      this.treeControl.expandDescendants(this.nestedNodeMap.get(newItem));
    }
    this.handleDragEnd(event);
  }

  handleDragEnd(event) {
    this.dragNode = null;
    this.dragNodeExpandOverNode = null;
    this.dragNodeExpandOverTime = 0;
    this.dragNodeExpandOverArea = NaN;
    event.preventDefault();
  }

  getStyle(node: FileFlatNode) {
    if (this.dragNode === node) {
      return 'drag-start';
    } else if (this.dragNodeExpandOverNode === node) {
      switch (this.dragNodeExpandOverArea) {
        case 1:
          return 'drop-above';
        case -1:
          return 'drop-below';
        default:
          return 'drop-center';
      }
    }
  }

  deleteItem(node: FileFlatNode) {
    this.database.deleteItem(this.flatNodeMap.get(node));
  }

  saveTree(): void {
    const parsedTree = this.database.parseToObject(this.dataSource.data[0], {});

    this.hierarchyService.update({ tree: parsedTree }).subscribe(hierarchy => {
      this.hierarchyService.hierarchyId = hierarchy._id;
      this.toastr.info('Drzewo zostało zapisane');
    });
  }
}
