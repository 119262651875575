import { Component, Inject, OnInit } from '@angular/core';
import { FormBuilder, FormControl, FormGroup, Validators } from '@angular/forms';
import { MatDialogRef, MAT_DIALOG_DATA } from '@angular/material';
import { TranslateService } from '@ngx-translate/core';
import { Observable } from 'rxjs';
import { map } from 'rxjs/operators';
import { UnlockUserReasonEnum } from '../../../../enums/unlock-user-reason.enum';
import { BlockUserReasonEnum } from '../../../../enums/block-user-reason.enum';
import { UserHistoryStatusInterface } from '../../../../interfaces/user-history-status.iface';
import { UserIface } from '../../../../interfaces/user.iface';
import { UserService } from '../../../../services/user.service';
import { ConfirmDialogInterface } from '../../../../shared/interfaces/confirm-dialog.interface';
import { ToggleUserBaseComponent } from '../base/toggle-user-base.component';

@Component({
  selector: 'app-unlock-user',
  templateUrl: './unlock-user-dialog.component.html',
  styleUrls: ['./unlock-user-dialog.component.scss']
})
export class UnlockUserDialogComponent extends ToggleUserBaseComponent<UnlockUserDialogComponent> implements OnInit {
  userHistoryStatuses$: Observable<UserHistoryStatusInterface[]>;

  constructor(
    @Inject(MAT_DIALOG_DATA) public data: ConfirmDialogInterface<UserIface>,
    protected refDialog: MatDialogRef<UnlockUserDialogComponent>,
    protected formBuilder: FormBuilder,
    protected translate: TranslateService,
    private userService: UserService
  ) {
    super(refDialog, formBuilder, translate);
  }

  ngOnInit() {
    this.selectOptions = this.optionsFromEnum(UnlockUserReasonEnum, 'UNLOCK_USER_REASON_ENUM');
    this.userHistoryStatuses$ = this.userService
      .findLastHistoryStatus(this.data.customData._id)
      .pipe(map(status => [status]));
  }

  loadAllUserHistoryStatuses() {
    this.userHistoryStatuses$ = this.userService.findAllHistoryStatuses(this.data.customData._id);
  }

  protected setupForm(): FormGroup {
    return this.formBuilder.group({
      reason: new FormControl('', Validators.required)
    });
  }
}
