import { Component, OnInit } from '@angular/core';
import { FormBuilder, FormControl, FormGroup, Validators } from '@angular/forms';
import { UserService } from '../../../services/user.service';
import { UserIface } from '../../../interfaces/user.iface';
import { AuthService } from '../../../services/auth.service';
import { PasswordValidator } from '../../../helpers/password-validator';
import { ToastrService } from 'ngx-toastr';
import { TranslateService } from '@ngx-translate/core';

@Component({
  selector: 'app-account',
  templateUrl: './account.component.html',
  styleUrls: ['./account.component.scss']
})
export class AccountComponent implements OnInit {
  public passwordChangeForm: FormGroup;
  public roles = [];
  private user: UserIface;

  constructor(
    private userService: UserService,
    private formBuilder: FormBuilder,
    private authService: AuthService,
    private toastr: ToastrService,
    private translate: TranslateService
  ) {}

  ngOnInit() {
    this.user = this.authService.user;

    const browserLang = this.translate.currentLang;
    this.translate.use(browserLang.match(/en|pl/) ? browserLang : 'pl');
    const passwordStrength = '^(?=.*[a-z])(?=.*[A-Z])(?=.*\\d)(?=.*[#$^+=!*()@%&]).{10,}$';
    this.passwordChangeForm = this.formBuilder.group(
      {
        oldPassword: new FormControl('', Validators.required),
        password: new FormControl('', [Validators.required, Validators.pattern(passwordStrength)]),
        confirmPassword: new FormControl('', [Validators.required])
      },
      {
        validator: PasswordValidator.validate
      }
    );
  }

  changePassword(form: FormGroup): void {
    this.passwordChangeForm.disable();

    const { oldPassword, password } = form.value;
    this.userService.updatePassword({ id: this.user.userId, oldPassword, password }).subscribe(
      () => {
        this.passwordChangeForm.reset();
        this.passwordChangeForm.enable();

        Object.keys(this.passwordChangeForm.controls).forEach(key => {
          this.passwordChangeForm.controls[key].setErrors(null);
        });
        this.toastr.success('Zaktualizowano hasło');
      },
      () => {
        this.passwordChangeForm.enable();
        this.toastr.error('Niestety nie udało się zaktualizować hasła');
      }
    );
  }
}
