import { Component, Input, OnInit } from '@angular/core';
import { TranslateService } from '@ngx-translate/core';
import { BlockUserReasonEnum } from '../../../../../enums/block-user-reason.enum';
import { UnlockUserReasonEnum } from '../../../../../enums/unlock-user-reason.enum';
import { UserStatusHistoryEnum } from '../../../../../enums/user-status-history.enum';
import { UserHistoryStatusInterface } from '../../../../../interfaces/user-history-status.iface';

@Component({
  selector: 'app-user-history-status-item',
  templateUrl: './user-history-status-item.component.html',
  styleUrls: ['./user-history-status-item.component.scss']
})
export class UserHistoryStatusItemComponent implements OnInit {
  @Input() status: UserHistoryStatusInterface;

  constructor(private translate: TranslateService) {
    const browserLang = translate.currentLang;
    translate.use(browserLang.match(/en|pl/) ? browserLang : 'pl');
  }

  ngOnInit() {}

  getBlockedStatusName(status: BlockUserReasonEnum): string {
    const valuesFromEnum = Object.values(BlockUserReasonEnum);
    return `BLOCK_USER_REASON_ENUM.${valuesFromEnum[status]}`;
  }

  getUnlockedStatusName(status: UnlockUserReasonEnum): string {
    const valuesFromEnum = Object.values(UnlockUserReasonEnum);
    return `UNLOCK_USER_REASON_ENUM.${valuesFromEnum[status]}`;
  }

  hasBlockedStatus(data: UserHistoryStatusInterface): boolean {
    return data.status === UserStatusHistoryEnum.Blocked;
  }
}
