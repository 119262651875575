import { Pipe, PipeTransform } from '@angular/core';
import { LoggerService } from '../services/logger.service';

/*
 * Displays number of day passed since date
 * Usage:
 *   value | daysSince
 * Example:
 *   {{ dateString | daysSince }}
 *   formats to: 1024
 */
@Pipe({ name: 'daysSince' })
export class DaysSincePipe implements PipeTransform {
  transform(value: string): string {
    const now = new Date();
    const milisInADay = 1000 * 60 * 60 * 24;
    const daysSince = (now.getTime() - Date.parse(value)) / milisInADay;

    LoggerService.log(`DaysSincePipe::transform`, `${value} -> ${daysSince}`);
    const fulDaysDiff = Math.floor(daysSince);
    if (fulDaysDiff < 1) {
      return '< 1 dzień temu';
    } else if (fulDaysDiff === 1) {
      return `${fulDaysDiff} dzień temu`;
    } else {
      return `${fulDaysDiff} dni temu`;
    }
  }
}
