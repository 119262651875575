import { Injectable } from '@angular/core';
import { environment } from '../../environments/environment';
import { Event, Tag } from '../interfaces/event';
import { HttpClient } from '@angular/common/http';
import { Observable } from 'rxjs/internal/Observable';
import { UserModel } from '../models/user.model';

@Injectable()
export class EventsService {
  private static readonly USER_FETCHED = 'user_fetched';
  private url: string;

  constructor(private http: HttpClient) {
    this.url = `${environment.EVENTS_API_URL}`;
  }

  createEvent(event: Event): Observable<Event> {
    return this.http.post<Event>(this.url, event);
  }

  getFetchUserEvent(user: UserModel, ip: string): Event {
    const tags: Tag[] = [
      {
        category: 'eventType',
        value: EventsService.USER_FETCHED
      },
      {
        category: 'userRole',
        value: user.getSalesChannel()
      },
      {
        category: 'userType',
        value: user.get('userType')
      },
      {
        category: 'username',
        value: user.get('username')
      },
      {
        category: 'userCreated',
        value: user.get('created')
      },
      {
        category: 'userIP',
        value: ip
      }
    ];

    return {
      value: 1,
      eventType: EventsService.USER_FETCHED,
      tags
    };
  }
}
