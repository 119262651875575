import { Injectable } from '@angular/core';
import { Observable } from 'rxjs';
import { HttpClient, HttpParams } from '@angular/common/http';
import { environment } from '../../environments/environment';
import { CRUD } from '../interfaces/crud.interface';
import { Count } from '../interfaces/count.interface';
import { CustomerSegmentIface } from '../interfaces/customer-segment.iface';

@Injectable()
export class CustomerSegmentsService implements CRUD<CustomerSegmentIface> {
  private readonly url: string;

  constructor(private http: HttpClient) {
    this.url = `${environment.CUSTOMER_SEGMENT_API_URL}`;
  }

  findAll(query: any, pageIndex: number, pageSize: number): Observable<CustomerSegmentIface[]> {
    const queryParams = new HttpParams()
      .set('filter', JSON.stringify(query))
      .set('pageIndex', pageIndex.toString())
      .set('pageSize', pageSize.toString());

    return this.http.get<CustomerSegmentIface[]>(this.url, { params: queryParams });
  }

  findById(id: string): Observable<CustomerSegmentIface> {
    return this.http.get<CustomerSegmentIface>(`${this.url}/${id}`);
  }

  count(query: any): Observable<Count> {
    const queryParams = new HttpParams().set('filter', JSON.stringify(query));
    return this.http.get<Count>(`${this.url}/count`, { params: queryParams });
  }

  create(segment: CustomerSegmentIface): Observable<CustomerSegmentIface> {
    return this.http.post<CustomerSegmentIface>(this.url, segment);
  }

  update(id: string, segment: CustomerSegmentIface): Observable<CustomerSegmentIface> {
    return this.http.put<CustomerSegmentIface>(`${this.url}/${id}`, segment);
  }

  remove(id: string): Observable<CustomerSegmentIface> {
    return this.http.delete<CustomerSegmentIface>(`${this.url}/${id}`);
  }
}
