import { Component, OnInit } from '@angular/core';
import { MatAutocompleteSelectedEvent, MatDialogRef } from '@angular/material';
import { UserIface } from '../../../../interfaces/user.iface';
import { Observable } from 'rxjs/internal/Observable';
import { FormControl } from '@angular/forms';
import { debounce, switchMap } from 'rxjs/operators';
import { UserService } from '../../../../services/user.service';
import { timer } from 'rxjs/internal/observable/timer';
import { TranslateService } from '@ngx-translate/core';

@Component({
  selector: 'app-add-user',
  templateUrl: './add-user-dialog.component.html',
  styleUrls: ['./add-user-dialog.component.scss']
})
export class AddUserDialogComponent implements OnInit {
  public namesBoss: string[] = [];
  public namesUser: string[] = [];

  public userFilter: FormControl = new FormControl();
  public bossFilter: FormControl = new FormControl();
  public filteredOptionsBoss: Observable<UserIface[]>;
  public filteredOptionsUser: Observable<UserIface[]>;

  private static getUsernameDBFilter(username: string): object {
    return { username: { $regex: username, $options: 'i' } };
  }

  constructor(
    private dialogRef: MatDialogRef<AddUserDialogComponent>,
    private userService: UserService,
    private translate: TranslateService
  ) {
    const browserLang = translate.currentLang;
    translate.use(browserLang.match(/en|pl/) ? browserLang : 'pl');
  }

  ngOnInit() {
    this.filteredOptionsBoss = this.bossFilter.valueChanges.pipe(
      debounce(() => timer(400)),
      switchMap(val => this.userService.findAll(AddUserDialogComponent.getUsernameDBFilter(val), 0, 0))
    );

    this.filteredOptionsUser = this.userFilter.valueChanges.pipe(
      debounce(() => timer(400)),
      switchMap(val => this.userService.findAll(AddUserDialogComponent.getUsernameDBFilter(val), 0, 0))
    );
  }

  addUser(selected: MatAutocompleteSelectedEvent): void {
    this.namesUser.push(selected.option.value);
  }

  deleteUser(index: number): void {
    this.namesUser.splice(index, 1);
  }

  addBoss(selected: MatAutocompleteSelectedEvent): void {
    this.namesBoss.push(selected.option.value);
  }

  deleteBoss(index: number): void {
    this.namesBoss.splice(index, 1);
  }

  disabledBossInput(): boolean {
    return this.namesBoss.length >= 1;
  }

  save(namesBoss: string[], namesUser: string[]): void {
    const data = {
      namesBoss: namesBoss,
      namesUser: namesUser
    };
    this.dialogRef.close(data);
  }

  verifyUserName(): boolean {
    return this.namesUser.includes(this.namesBoss[0]);
  }
}
