import { Component, OnInit, ViewChild } from '@angular/core';
import { FormBuilder, FormControl, FormGroup, ValidationErrors, Validators } from '@angular/forms';
import { UserService } from '../../../services/user.service';
import { MetadataIface, UserIface } from '../../../interfaces/user.iface';
import { MatChipInputEvent, MatPaginator, MatTableDataSource } from '@angular/material';
import { Router } from '@angular/router';
import { ToastrService } from 'ngx-toastr';
import { TranslateService } from '@ngx-translate/core';
import { AuthService } from '../../../services/auth.service';
import { SupportedRoles } from '../../../enums/supportedRoles.enum';
import { PasswordGenerator } from '../../../../../src/app/helpers/password-generator';
@Component({
  selector: 'app-user-add',
  templateUrl: './user-add.component.html',
  styleUrls: ['./user-add.component.scss']
})
export class UserAddComponent implements OnInit {
  public addUserForm: FormGroup;
  public userMetadata = {};
  public supportedRoles = Object.values(SupportedRoles);
  public dataSource: MatTableDataSource<MetadataIface>;
  public displayedColumns = ['key', 'value'];
  public metadata: MetadataIface[] = [];

  @ViewChild(MatPaginator) paginator: MatPaginator;

  constructor(
    private userService: UserService,
    private formBuilder: FormBuilder,
    private router: Router,
    private toastr: ToastrService,
    private translate: TranslateService,
    public authService: AuthService
  ) {}

  ngOnInit(): void {
    this.translateInit();
    this.createForm();
  }

  createForm(): void {
    this.dataSource = new MatTableDataSource(this.metadata);
    const passwordStrength = '^(?=.*[a-z])(?=.*[A-Z])(?=.*\\d)(?=.*[#$^+=!*()@%&]).{10,}$';
    this.addUserForm = this.formBuilder.group({
      username: new FormControl('', [Validators.required]),
      salesmanCode: new FormControl('', []),
      sellerAccountId: new FormControl('', []),
      password: new FormControl('', [Validators.required, Validators.pattern(passwordStrength)]),
      email: new FormControl('', []),
      roles: new FormControl('', [Validators.required])
    });
    this.setEmailValidators();
  }

  translateInit(): void {
    const browserLang = this.translate.currentLang;
    this.translate.use(browserLang.match(/en|pl/) ? browserLang : 'pl');
  }

  setEmailValidators(): void {
    if (this.authService.featureTogglesFlags['FEAT_642']) {
      this.addUserForm.controls['email'].setValidators([Validators.required, Validators.email]);
    }
  }

  generatePassword(): void {
    const generatePass = PasswordGenerator.create();
    this.addUserForm.controls['password'].setValue(generatePass);
  }

  addMetadata(): void {
    this.metadata.push({ key: '', value: '' });
    this.dataSource.data = this.metadata;
    this.dataSource.paginator = this.paginator;
  }

  saveMetadata(): void {
    this.userMetadata = {};
    this.metadata.forEach(metadata => {
      this.userMetadata[metadata.key] = metadata.value;
    });
    if (this.addUserForm.get('salesmanCode').value) {
      this.userMetadata['salesmanCode'] = this.addUserForm.get('salesmanCode').value;
    }
    if (this.addUserForm.get('sellerAccountId').value) {
      this.userMetadata['sellerAccountId'] = this.addUserForm.get('sellerAccountId').value;
    }
  }

  deleteMetadata(index: number): void {
    this.metadata.splice(index, 1);
    this.dataSource.data = this.metadata;
  }

  addUser(form: FormGroup): void {
    this.saveMetadata();
    this.addUserForm.disable();

    const user: UserIface = {
      userType: 'USERPASS',
      username: form.value.username,
      password: form.value.password,
      roles: form.value.roles,
      metadata: this.userMetadata
    };
    if (this.authService.featureTogglesFlags['FEAT_642']) {
      user.email = form.value.email;
    }

    this.userService.create(user).subscribe(
      () => {
        this.addUserForm.enable();

        this.router.navigate(['/user-list']);
        this.toastr.success('Dodano użytkownika');
      },
      () => {
        this.addUserForm.enable();
        this.toastr.error('Niestety nie udało się dodać użytkownika');
      }
    );
  }

  copyMessage(val: string) {
    const selBox = document.createElement('textarea');
    selBox.style.position = 'fixed';
    selBox.style.left = '0';
    selBox.style.top = '0';
    selBox.style.opacity = '0';
    selBox.value = val;
    document.body.appendChild(selBox);
    selBox.focus();
    selBox.select();
    document.execCommand('copy');
    document.body.removeChild(selBox);
  }
}
